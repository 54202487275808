import React, { Component } from "react";
import Navbar from "../../config/Navbar";
import Footer from "../../config/Footer";
import "./styles.css";
var I18n = require("react-redux-i18n").I18n;
export default class taxation extends Component {
  constructor() {
    super();
    this.state = {
      arr1: [
        { id: 1, list: "taxation.heading3contentList1" },
        { id: 2, list: "taxation.heading3contentList2" },
        { id: 3, list: "taxation.heading3contentList3" },
        { id: 4, list: "taxation.heading3contentList4" },
        { id: 5, list: "taxation.heading3contentList5" },
        { id: 6, list: "taxation.heading3contentList6" },
        { id: 7, list: "taxation.heading3contentList7" },
        { id: 8, list: "taxation.heading3contentList8" },
        { id: 9, list: "taxation.heading3contentList9" },
      ],
      arr2: [
        { id: 1, list: "taxation.heading4contentList1" },
        { id: 2, list: "taxation.heading4contentList2" },
        { id: 3, list: "taxation.heading4contentList3" },
        { id: 4, list: "taxation.heading4contentList4" },
        { id: 5, list: "taxation.heading4contentList5" },
        { id: 6, list: "taxation.heading4contentList6" },
        { id: 7, list: "taxation.heading4contentList7" },
      ],
    };
  }
  handleOnHome = (event) => {
    //.current is verification that your element has rendered
    // window.scrollTo(0, this.myRefHome.current.offsetTop);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };
  render() {
    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />
          <Navbar />
          <main id="main">
            {/* <!-- ======= Breadcrumbs ======= --> */}
            <section id="breadcrumbs" class="breadcrumbs">
              <div class="container ">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li> {this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
              <div class="container text-center">
                <h1>{I18n.t("home.servicesubHeading11").toUpperCase()}</h1>
              </div>
            </section>
            {/* <!-- End Breadcrumbs --> */}
            {/* <!-- ======= About Section ======= --> */}
            <section id="about" class="about">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12 pt-4 pt-lg-0 content">
                    <p class="text-justify">{I18n.t("taxation.content1")}</p>
                    <p class="text-justify">{I18n.t("taxation.content2")}</p>
                    <p class="text-justify">{I18n.t("taxation.content3")}</p>
                  </div>
                </div>
              </div>
            </section>{" "}
            <section id="clients" class="clients">
              <div class="container">
                <h3 class="text-center">
                  {I18n.t("taxation.heading1").toUpperCase()}
                </h3>
                <div class="accordion" id="accordionExample">
                  <div class="card">
                    <div
                      class="card-header1 "
                      id="headingOne"
                      style={{ backgroundColor: "#0690ce" }}
                    >
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          style={{
                            color: "white",
                            textDecoration: "none",
                          }}
                        >
                          {I18n.t("taxation.heading2")}
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne"
                      class="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        <ul style={{ listStyle: "none" }}>
                          <li>
                            {" "}
                            <i class="icofont-check-circled liIcon"></i>{" "}
                            {I18n.t("taxation.heading2contentList1")}
                          </li>
                          <li>
                            {" "}
                            <i class="icofont-check-circled liIcon"></i>{" "}
                            {I18n.t("taxation.heading2contentList2")}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div
                      class="card-header1 "
                      id="headingTwo"
                      style={{ backgroundColor: "#0690ce" }}
                    >
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="true"
                          aria-controls="collapseTwo"
                          style={{
                            color: "white",
                            textDecoration: "none",
                          }}
                        >
                          {I18n.t("taxation.heading3")}
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseTwo"
                      class="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        <ul style={{ listStyle: "none" }}>
                          {this.state.arr1.map((e, i) => {
                            return (
                              <li key={e.id}>
                                <i class="icofont-check-circled liIcon"></i>{" "}
                                {I18n.t(e.list)}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div
                      class="card-header1 "
                      id="headingThree"
                      style={{ backgroundColor: "#0690ce" }}
                    >
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="true"
                          aria-controls="collapseThree"
                          style={{
                            color: "white",
                            textDecoration: "none",
                          }}
                        >
                          {I18n.t("taxation.heading4")}
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseThree"
                      class="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        <ul style={{ listStyle: "none" }}>
                          {this.state.arr2.map((e, i) => {
                            return (
                              <li key={e.id}>
                                <i class="icofont-check-circled liIcon"></i>{" "}
                                {I18n.t(e.list)}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
        <div>
          <Footer />
        </div>

        <a onClick={() => this.handleOnHome()} class="back-to-top">
          <i class="icofont-simple-up"></i>
        </a>
      </div>
    );
  }
}
