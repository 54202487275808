import React, { Component } from "react";
import { Link } from "react-router-dom";
// import firebase from './firebase'
import logo from "../../assets/userLogo.png";
import pix1 from "../../assets/successful-business-partners-discussing-contract.21625cec.jpg";
import pix2 from "../../assets/successful-business-partners-discussing-contract2.bd46cc26.jpg";
import pix3 from "../../assets/successful-business-partners-discussing-contract3.178dfe6d.jpg";
import pix4 from "../../assets/commericalImage.jpg";
import CompanySecretarialServices from "../../assets/imgHome/CompanySecretarialServices.jpg";
import Corporate from "../../assets/imgHome/Corporate.jpg";
import Disputeresolution from "../../assets/imgHome/Disputeresolution.jpg";
import EmploymentLabour from "../../assets/imgHome/EmploymentLabour.jpg";
import Finacebanking from "../../assets/imgHome/Finacebanking.jpg";
import IntellectualProperty from "../../assets/imgHome/IntellectualProperty.jpg";
import PrivateClientsServices from "../../assets/imgHome/PrivateClientsServices.jpg";
import PropertyRealEstate from "../../assets/imgHome/PropertyRealEstate.jpg";
import FamilyLaw from "../../assets/imgHome/familyLaw.jpg";
import TaxationImg from "../../assets/imgHome/taxation.jpg";
// import CompanySecretarialServices from "../../imgHome/CompanySecretarialServices.jpg";
import Navbar from "../../config/Navbar";
import Footer from "../../config/Footer";
import { connect } from "react-redux";
import "../../App.css";
var I18n = require("react-redux-i18n").I18n;

// import logo from "../assets/userLogo.png";
// import { ourStoryText } from "../../l10n/translations";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arr: [
        {
          id: 1,
          link: "/Banking-and-finance",
          imageCardBg: Finacebanking,
          name: "home.servicesubHeading1",
        },
        {
          id: 2,
          link: "/company-secretarial",
          imageCardBg: CompanySecretarialServices,
          name: "home.servicesubHeading2",
        },
        {
          id: 3,
          link: "/commercial",
          imageCardBg: pix4,
          name: "home.servicesubHeading3",
        },
        {
          id: 4,
          link: "/corporate",
          imageCardBg: Corporate,
          name: "home.servicesubHeading4",
        },
        {
          id: 5,
          link: "/dispute-resolution-litigation",
          imageCardBg: Disputeresolution,
          name: "home.servicesubHeading5",
        },
        {
          id: 6,
          link: "/employment-and-labour",
          imageCardBg: EmploymentLabour,
          name: "home.servicesubHeading6",
        },
        {
          id: 7,
          link: "/intellectual-property",
          imageCardBg: IntellectualProperty,
          name: "home.servicesubHeading7",
        },
        {
          id: 8,
          link: "/private-client-services",
          imageCardBg: PrivateClientsServices,
          name: "home.servicesubHeading8",
        },
        {
          id: 9,
          link: "/property-&-real-estate",
          imageCardBg: PropertyRealEstate,
          name: "home.servicesubHeading9",
        },
        {
          id: 10,
          link: "/family-law",
          imageCardBg: FamilyLaw,
          name: "home.servicesubHeading10",
        },
        {
          id: 11,
          link: "/taxation",
          imageCardBg: TaxationImg,
          name: "home.servicesubHeading11",
        },
      ],
    };
    this.myRefHome = React.createRef();
    this.myRef = React.createRef();
    // this.myRef = React.createRef();
  }
  handleOnHome = (event) => {
    //.current is verification that your element has rendered
    // window.scrollTo(0, this.myRefHome.current.offsetTop);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };
  executeScroll = () =>
    this.myRef.current.scrollIntoView({ behavior: "smooth" });
  render() {
    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />

          <section id="hero">
            <div class="hero-container">
              <div
                id="heroCarousel"
                class="carousel slide carousel-fade"
                data-ride="carousel"
              >
                <ol
                  class="carousel-indicators"
                  id="hero-carousel-indicators"
                ></ol>

                <div class="carousel-inner" role="listbox">
                  <div
                    class="carousel-item active"
                    style={{
                      background: `url(${pix2})`,
                      height: "100vh",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div class="carousel-container">
                      <div class="carousel-content">
                        {/* <h2 class="animate__animated animate__fadeInDown"> */}
                        {/* Welcome to <span>Eterna</span> */}
                        {/* {I18n.t("home.title")}
                        </h2> */}
                        <img
                          class="animate__animated animate__fadeInDown"
                          src={logo}
                          alt="userLogo"
                          // style={{
                          //   height: "200px",
                          // }}
                          id="logoResize"
                        />
                        {/* <p class="animate__animated animate__fadeInUp">
                          Ut velit est quam dolor ad a aliquid qui aliquid.
                          Sequi ea ut et est quaerat sequi nihil ut aliquam.
                          Occaecati alias dolorem mollitia ut. Similique ea
                          voluptatem. Esse doloremque accusamus repellendus
                          deleniti vel. Minus et tempore modi architecto.
                        </p> */}
                        {/* <a
                          href=""
                          class="btn-get-started animate__animated animate__fadeInUp"
                        > */}
                        {/* {I18n.t("home.subHeading")}*/}
                        {/* Read More
                        </a> */}
                      </div>
                    </div>
                  </div>
                  <div
                    class="carousel-item "
                    style={{
                      background: `url(${pix1})`,
                      height: "100vh",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div class="carousel-container">
                      <div class="carousel-content">
                        <h2
                          class="animate__animated animate__fadeInDown"
                          id="#headingTop"
                        >
                          {I18n.t("home.title")}
                        </h2>
                        <p class="animate__animated animate__fadeInUp">
                          {I18n.t("home.mainHeading")}
                        </p>
                        <button
                          onClick={() => this.executeScroll()}
                          class="btn-get-started animate__animated animate__fadeInUp"
                        >
                          {I18n.t("home.readMore")}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="carousel-item "
                    style={{
                      background: `url(${pix3})`,
                      // background: "url('assets/img/slide/slide-3.jpg')",
                      height: "100vh",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div class="carousel-container">
                      <div class="carousel-content">
                        <h2 class="animate__animated animate__fadeInDown">
                          {I18n.t("home.title")}
                        </h2>
                        <p class="animate__animated animate__fadeInUp">
                          {I18n.t("home.mainHeading")}
                        </p>
                        <button
                          onClick={() => this.executeScroll()}
                          class="btn-get-started animate__animated animate__fadeInUp"
                        >
                          {I18n.t("home.readMore")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <a
                  class="carousel-control-prev"
                  href="#heroCarousel"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon icofont-rounded-left"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Previous</span>
                </a>

                <a
                  class="carousel-control-next"
                  href="#heroCarousel"
                  role="button"
                  data-slide="next"
                >
                  <span
                    class="carousel-control-next-icon icofont-rounded-right"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
            </div>
          </section>
          <main id="main">
            {/* <section id="featured" class="featured">
              <div class="container">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="icon-box">
                      <i class="icofont-computer"></i>
                      <h3>
                        <a href="">Lorem Ipsum</a>
                      </h3>
                      <p>
                        Voluptatum deleniti atque corrupti quos dolores et quas
                        molestias excepturi sint occaecati cupiditate non
                        provident
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-4 mt-4 mt-lg-0">
                    <div class="icon-box">
                      <i class="icofont-image"></i>
                      <h3>
                        <a href="">Dolor Sitema</a>
                      </h3>
                      <p>
                        Minim veniam, quis nostrud exercitation ullamco laboris
                        nisi ut aliquip ex ea commodo consequat tarad limino ata
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-4 mt-4 mt-lg-0">
                    <div class="icon-box">
                      <i class="icofont-tasks-alt"></i>
                      <h3>
                        <a href="">Sed ut perspiciatis</a>
                      </h3>
                      <p>
                        Duis aute irure dolor in reprehenderit in voluptate
                        velit esse cillum dolore eu fugiat nulla pariatur
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}

            <section id="about" class="about">
              <div class="container">
                <div class="section-title" data-aos="fade-up" ref={this.myRef}>
                  <h2>{I18n.t("home.aboutSectionTitle")}</h2>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <img src={pix2} class="img-fluid" alt="" />
                  </div>
                  <div class="col-lg-6 pt-4 pt-lg-0 content">
                    <h3 class="text-center">
                      {I18n.t("home.aboutSectionLegal")}
                    </h3>
                    {/* <p class="font-italic">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p> */}
                    {/* <ul>
                      <li>
                        <i class="icofont-check-circled"></i> Ullamco laboris
                        nisi ut aliquip ex ea commodo consequat.
                      </li>
                      <li>
                        <i class="icofont-check-circled"></i> Duis aute irure
                        dolor in reprehenderit in voluptate velit.
                      </li>
                      <li>
                        <i class="icofont-check-circled"></i> Ullamco laboris
                        nisi ut aliquip ex ea commodo consequat. Duis aute irure
                        dolor in reprehenderit in voluptate trideta
                        storacalaperda mastiro dolore eu fugiat nulla pariatur.
                      </li>
                    </ul> */}
                    <p class="text-justify">
                      {I18n.t("home.aboutSectionLegal1")}
                    </p>
                    <p class="text-justify">
                      {I18n.t("home.aboutSectionLegal2")}
                    </p>
                    <p class="text-justify">
                      {I18n.t("home.aboutSectionLegal3")}
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section id="services" class="services">
              <div class="container">
                <div class="section-title" data-aos="fade-up">
                  <h2> {I18n.t("home.serviceHeading")}</h2>
                </div>
                <div class="row mt-4">
                  {this.state.arr.map((e, i) => {
                    return (
                      <div class="col-md-4 col-sm-6 mb-4">
                        <div class="workingswrap" key={e.id}>
                          <a class="work halfheight" href={e.link}>
                            <div class="bg">
                              <img src={e.imageCardBg} alt={e.name} />
                            </div>
                            <div class="text">
                              <h5>{I18n.t(e.name)}</h5>
                            </div>
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>

                {/* <div class="col">
                    <div
                      class="icon-box"
                      style={{
                        boxShadow: "0px 2px 15px rgb(0 0 0 / 15%)",
                        borderRadius: "10px",
                        transition: "all ease-in-out 0.3s",
                        height: "100%",
                      }}
                    >
                      <div class="icon">
                        <i class="bx bx-tachometer"></i>
                      </div>
                      <h4>
                        <a href=""> {I18n.t("home.servicesubHeading9")}</a>
                      </h4>
                    </div>
                  </div> */}
              </div>
            </section>

            {/* <section id="clients" class="clients">
              <div class="container">
                <div class="section-title">
                  <h2>Clients</h2>
                  <p>
                    Magnam dolores commodi suscipit. Necessitatibus eius
                    consequatur ex aliquid fuga eum quidem. Sit sint consectetur
                    velit. Quisquam quos quisquam cupiditate. Et nemo qui
                    impedit suscipit alias ea. Quia fugiat sit in iste officiis
                    commodi quidem hic quas.
                  </p>
                </div>

                <div class="owl-carousel clients-carousel">
                  <img src="./assets/img/clients/client-1.png" alt="" />
                  <img src="./assets/img/clients/client-2.png" alt="" />
                  <img src="./assets/img/clients/client-3.png" alt="" />
                  <img src="./assets/img/clients/client-4.png" alt="" />
                  <img src="./assets/img/clients/client-5.png" alt="" />
                  <img src="./assets/img/clients/client-6.png" alt="" />
                  <img src="./assets/img/clients/client-7.png" alt="" />
                  <img src="./assets/img/clients/client-8.png" alt="" />
                </div>
              </div>
            </section> */}
          </main>

          <div>
            <Footer />
          </div>

          <a onClick={() => this.handleOnHome()} class="back-to-top">
            <i class="icofont-simple-up"></i>
          </a>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  console.log(
    state.i18n.locale === "en"
      ? state.i18n.locale.translations === "en"
      : state.i18n.locale.translations === "ch"
  );
  console.log(state.i18n);
  return { language: state.language };
};
// export default Home;
export default connect(mapStateToProps)(Home);

{
  /* <div class="workingswrap">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-3 col-sm-6">
                    <div class="row">
                      <a
                        class="work halfheight"
                        href="https://www.hmco.com.pk/capital-markets"
                      >
                        <div class="bg">
                          <img
                            src="https://www.hmco.com.pk/wp-content/themes/haidermotabnr/assets/images/typesimg1.jpg"
                            alt=""
                          />
                        </div>

                        <div class="text">
                          <h4>Capital Markets</h4>
                        </div>
                      </a>

                      <a
                        class="work halfheight"
                        href="https://www.hmco.com.pk/corporate-commercial-advisory"
                      >
                        <div class="bg">
                          <img
                            src="https://www.hmco.com.pk/wp-content/themes/haidermotabnr/assets/images/typesimg2.jpg"
                            alt=""
                          />
                        </div>

                        <div class="text">
                          <h4>
                            Corporate <span>Advisory</span>
                          </h4>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-6">
                    <div class="row">
                      <a
                        class="work fullheight"
                        href="https://www.hmco.com.pk/dispute-resolution"
                      >
                        <div class="bg">
                          <img
                            src="https://www.hmco.com.pk/wp-content/themes/haidermotabnr/assets/images/typesimg3.jpg"
                            alt=""
                          />
                        </div>

                        <div class="text">
                          <h4>
                            Dispute <span>Resolution</span>
                          </h4>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-6">
                    <div class="row">
                      <a
                        class="work halfheight"
                        href="https://www.hmco.com.pk/banking-finance"
                      >
                        <div class="bg">
                          <img
                            src="https://www.hmco.com.pk/wp-content/themes/haidermotabnr/assets/images/typesimg4.jpg"
                            alt=""
                          />
                        </div>

                        <div class="text">
                          <h4>
                            Banking <span>& Finance</span>
                          </h4>
                        </div>
                      </a>

                      <a
                        class="work halfheight"
                        href="https://www.hmco.com.pk/technology-media-telecom/"
                      >
                        <div class="bg">
                          <img
                            src="https://www.hmco.com.pk/wp-content/themes/haidermotabnr/assets/images/typesimg5.jpg"
                            alt=""
                          />
                        </div>

                        <div class="text">
                          <h4>
                            Technology, Media <span>& Telecom</span>{" "}
                          </h4>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-6">
                    <div class="row">
                      <a
                        class="work halfheight"
                        href="https://www.hmco.com.pk/energy"
                      >
                        <div class="bg">
                          <img
                            src="https://www.hmco.com.pk/wp-content/themes/haidermotabnr/assets/images/typesimg6.jpg"
                            alt=""
                          />
                        </div>

                        <div class="text">
                          <h4>
                            Projects & <span>Energy</span>
                          </h4>
                        </div>
                      </a>

                      <a
                        class="work halfheight"
                        href="https://www.hmco.com.pk/mergers-acquisitions"
                      >
                        <div class="bg">
                          <img
                            src="https://www.hmco.com.pk/wp-content/themes/haidermotabnr/assets/images/typesimg7.jpg"
                            alt=""
                          />
                        </div>

                        <div class="text">
                          <h4>
                            Mergers & <span>Acquisitions </span>
                          </h4>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */
}
