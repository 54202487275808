import React, { Component } from "react";

// import firebase from './firebase'
// import logo from "../../assets/userLogo.png";
// import pix1 from "../../assets/pix1.jpg";
// import pix2 from "../../assets/pix2.jpg";
import Navbar from "../../config/Navbar";
import Footer from "../../config/Footer";
import "./styles.css";
var I18n = require("react-redux-i18n").I18n;
export default class propertyRealState extends Component {
  handleOnHome = (event) => {
    //.current is verification that your element has rendered
    // window.scrollTo(0, this.myRefHome.current.offsetTop);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };
  render() {
    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />

          <main id="main">
            {/* <!-- ======= Breadcrumbs ======= --> */}
            <section id="breadcrumbs" class="breadcrumbs">
              <div class="container ">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li> {this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
              <div class="container text-center">
                <h1>{I18n.t("home.servicesubHeading10").toUpperCase()}</h1>
              </div>
            </section>

            {/* <!-- End Breadcrumbs --> */}

            {/* <!-- ======= About Section ======= --> */}
            <section id="about" class="about">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12 pt-4 pt-lg-0 content">
                    <p class="text-justify">{I18n.t("familyLaw.content1")}</p>
                    <p class="text-justify">{I18n.t("familyLaw.content2")}</p>
                  </div>
                </div>
              </div>
            </section>
            <section id="clients" class="clients">
              <div class="container">
                <div class="accordion" id="accordionExample">
                  <div class="card">
                    <div
                      class="card-header1 "
                      id="headingOne"
                      style={{ backgroundColor: "#0690ce" }}
                    >
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          style={{ color: "white", textDecoration: "none" }}
                        >
                          {I18n.t("familyLaw.heading1")}
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne"
                      class="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        <p class="text-justify">
                          {I18n.t("familyLaw.heading1Content1")}
                        </p>
                        <p class="text-justify">
                          {I18n.t("familyLaw.heading1Content2")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div
                      class="card-header1 "
                      id="headingTwo"
                      style={{ backgroundColor: "#0690ce" }}
                    >
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="true"
                          aria-controls="collapseTwo"
                          style={{ color: "white", textDecoration: "none" }}
                        >
                          {I18n.t("familyLaw.heading2")}
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseTwo"
                      class="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        <p class="text-justify">
                          {I18n.t("familyLaw.heading2Content1")}
                        </p>
                        <p class="text-justify">
                          {I18n.t("familyLaw.heading2Content2")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div
                      class="card-header1 "
                      id="headingThree"
                      style={{ backgroundColor: "#0690ce" }}
                    >
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="true"
                          aria-controls="collapseThree"
                          style={{ color: "white", textDecoration: "none" }}
                        >
                          {I18n.t("familyLaw.heading3")}
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseThree"
                      class="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        <p class="text-justify">
                          {I18n.t("familyLaw.heading3Content1")}
                        </p>
                        <p class="text-justify">
                          {I18n.t("familyLaw.heading3Content2")}
                        </p>
                        <p class="text-justify">
                          {I18n.t("familyLaw.heading3Content3")}
                        </p>
                        <p class="text-justify">
                          {I18n.t("familyLaw.heading3Content4")}
                        </p>
                        <ul style={{ listStyle: "none" }}>
                          <li style={{ fontSize: "16px" }}>
                            <i class="icofont-check-circled liIcon"></i>
                            {I18n.t("familyLaw.contentList1")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            <i class="icofont-check-circled liIcon"></i>
                            {I18n.t("familyLaw.contentList2")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            <i class="icofont-check-circled liIcon"></i>
                            {I18n.t("familyLaw.contentList3")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            <i class="icofont-check-circled liIcon"></i>
                            {I18n.t("familyLaw.contentList4")}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="card">
                      <div
                        class="card-header1 "
                        id="headingFour"
                        style={{ backgroundColor: "#0690ce" }}
                      >
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseFour"
                            aria-expanded="true"
                            aria-controls="collapseFour"
                            style={{ color: "white", textDecoration: "none" }}
                          >
                            {I18n.t("familyLaw.heading4")}
                          </button>
                        </h2>
                      </div>

                      <div
                        id="collapseFour"
                        class="collapse"
                        aria-labelledby="headingFour"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <p class="text-justify">
                            {I18n.t("familyLaw.heading4Content1")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
        <div>
          <Footer />
        </div>

        <a onClick={() => this.handleOnHome()} class="back-to-top">
          <i class="icofont-simple-up"></i>
        </a>
      </div>
    );
  }
}
