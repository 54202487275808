import React, { Component } from "react";

// import firebase from './firebase'
// import logo from "../../assets/userLogo.png";
// import pix1 from "../../assets/pix1.jpg";
// import pix2 from "../../assets/pix2.jpg";

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import Navbar from "../../config/Navbar";
import Footer from "../../config/Footer";
var I18n = require("react-redux-i18n").I18n;
export default class BankFinance extends Component {
  constructor() {
    super();
    this.state = {
      activeTab: "1",
      tabName: `${I18n.t("whoWeAre.heading1")}`,
    };
  }

  toggle = (tab, tabName) => {
    const { activeTab } = this.state;
    console.log(tabName);
    // console.log(activeTab);
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
        tabName,
      });
    }
  };
  handleOnHome = (event) => {
    //.current is verification that your element has rendered
    // window.scrollTo(0, this.myRefHome.current.offsetTop);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };
  render() {
    const { activeTab, tab, tabName } = this.state;
    console.log(activeTab);
    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />
          <Navbar />
          <main id="main">
            {/* <!-- ======= Breadcrumbs ======= --> */}
            <section id="breadcrumbs" class="breadcrumbs">
              <div class="container ">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li> {this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
              <div class="container text-center">
                <h1> {tabName.toUpperCase()}</h1>
              </div>
            </section>

            {/* <!-- End Breadcrumbs --> */}

            {/* <!-- ======= About Section ======= --> */}

            <section id="specials" class="specials">
              <div class="container">
                <Nav
                  tabs
                  className="nav nav-tabs d-flex justify-content-around mb-4"
                >
                  <NavItem className="nav-item">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        this.toggle("1", ` ${I18n.t("whoWeAre.heading1")}`);
                      }}
                    >
                      {I18n.t("whoWeAre.heading1")}
                    </NavLink>
                  </NavItem>
                  <NavItem className="nav-item">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        this.toggle("2", ` ${I18n.t("whoWeAre.heading2")}`);
                      }}
                    >
                      {I18n.t("whoWeAre.heading2")}
                    </NavLink>
                  </NavItem>
                  {/* <NavItem className="nav-item">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({ active: activeTab === "3" })}
                      onClick={() => {
                        this.toggle("3", "International Reach");
                      }}
                    >
                      International Reach
                    </NavLink>
                  </NavItem> */}
                  <NavItem className="nav-item">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({ active: activeTab === "4" })}
                      onClick={() => {
                        this.toggle("4", ` ${I18n.t("whoWeAre.heading4")}`);
                      }}
                    >
                      {I18n.t("whoWeAre.heading4")}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <div class="row">
                      <div class="col-lg-12 pt-4 pt-lg-0 content">
                        <p class="text-justify">
                          {I18n.t("whoWeAre.heading1Content1")}
                        </p>
                        <p class="text-justify">
                          {I18n.t("whoWeAre.heading1Content2")}
                        </p>

                        <ul style={{ listStyle: "none" }}>
                          <li style={{ fontSize: "16px" }}>
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                            {I18n.t("whoWeAre.clientContentList1")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                            {I18n.t("whoWeAre.clientContentList2")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                            {I18n.t("whoWeAre.clientContentList3")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                            {I18n.t("whoWeAre.clientContentList4")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            <i
                              class="icofont-check-circled text-justify"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                            {I18n.t("whoWeAre.clientContentList5")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                            {I18n.t("whoWeAre.clientContentList6")}
                          </li>
                        </ul>
                        <p class="text-justify">
                          {I18n.t("whoWeAre.heading1Content3")}
                        </p>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div class="row">
                      <div class="col-lg-12 pt-4 pt-lg-0 content">
                        <p class="text-justify">
                          {I18n.t("whoWeAre.heading2Content1")}
                        </p>
                        <p class="text-justify">
                          {I18n.t("whoWeAre.heading2Content2")}
                        </p>
                        <p class="text-justify">
                          {I18n.t("whoWeAre.heading2Content3")}
                        </p>
                        <p class="text-justify">
                          {I18n.t("whoWeAre.heading2Content4")}
                        </p>
                        <p class="text-justify">
                          {I18n.t("whoWeAre.heading2Content5")}
                        </p>
                        <p class="text-justify">
                          {I18n.t("whoWeAre.heading2Content6")}
                        </p>
                      </div>
                    </div>
                  </TabPane>
                  {/* <TabPane tabId="3">
                    <Row>
                      <Col sm="6">
                        <Card body>
                          <CardTitle>Special Title Treatment</CardTitle>
                          <CardText>
                            With supporting text below as a natural lead-in to
                            additional content.
                          </CardText>
                          <Button>Go somewhere</Button>
                        </Card>
                      </Col>
                      <Col sm="6">
                        <Card body>
                          <CardTitle>Special Title Treatment</CardTitle>
                          <CardText>
                            With supporting text below as a natural lead-in to
                            additional content.
                          </CardText>
                          <Button>Go somewhere</Button>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane> */}
                  <TabPane tabId="4">
                    <div class="row">
                      <div class="col-lg-12 pt-4 pt-lg-0 content">
                        <p class="text-justify">
                          {I18n.t("whoWeAre.heading4Content1")}
                        </p>
                        <p class="text-justify">
                          {I18n.t("whoWeAre.heading4Content2")}
                        </p>

                        <ul style={{ listStyle: "none" }}>
                          <li>
                            {" "}
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                            {I18n.t("whoWeAre.contentList1")}
                          </li>
                          <li>
                            {" "}
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                            {I18n.t("whoWeAre.contentList2")}
                          </li>
                          <li>
                            {" "}
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                            {I18n.t("whoWeAre.contentList3")}
                          </li>
                          <li>
                            {" "}
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                            {I18n.t("whoWeAre.contentList4")}
                          </li>
                          <li>
                            {" "}
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                            {I18n.t("whoWeAre.contentList5")}
                          </li>
                          <li>
                            {" "}
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                            {I18n.t("whoWeAre.contentList6")}
                          </li>
                          <li>
                            {" "}
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                            {I18n.t("whoWeAre.contentList7")}
                          </li>
                        </ul>
                        <p class="text-justify">
                          {I18n.t("whoWeAre.heading4Content3")}
                        </p>
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </section>
          </main>
        </div>
        <div>
          <Footer />
        </div>

        <a onClick={() => this.handleOnHome()} class="back-to-top">
          <i class="icofont-simple-up"></i>
        </a>
      </div>
    );
  }
}
