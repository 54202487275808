import React, { Component } from "react";
// import {} from "react-router"; // Note this extra line
// import { english_lang, chinese_lang, set_lang } from "../store/actions/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { setLocale } from "react-redux-i18n";

/************************* USERS SECTION ******************************/
import logo from "../assets/final.8f9f88ed.svg";
var I18n = require("react-redux-i18n").I18n;
class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
  }
  handleChange = (data) => {

    // localStorage.setItem("language", data.value);
    this.props.setLanguage(data);
    window.location.reload();
  };

  openModal = (event) => {
    document.body.classList.add("mobile-nav-active");
    this.setState({ showModal: true });
  };
  hideModal = (event) => {
    document.body.classList.remove("mobile-nav-active");
    this.setState({ showModal: false });
  };

  toggle(key) {
    console.log(key);
    this.setState((prevState) => {
      return {
        activeAcc: prevState.activeAcc === key ? false : key,
      };
    });
  }
  render() {
    return (
      <div>
        {/* <section id="topbar" class="d-none d-lg-block">
          <div class="container d-flex ">
            <div class="contact-info mr-auto">
              <i class="icofont-envelope"></i>
              <a href="mailto:contact@example.com">contact@example.com</a>
              <i class="icofont-phone"></i> +1 5589 55488 55
            </div>
            <div class="social-links">
              <a href="#" class="twitter">
                <i class="icofont-twitter"></i>
              </a>
              <a href="#" class="facebook">
                <i class="icofont-facebook"></i>
              </a>
              <a href="#" class="instagram">
                <i class="icofont-instagram"></i>
              </a>
              <a href="#" class="skype">
                <i class="icofont-skype"></i>
              </a>
              <a href="#" class="linkedin">
                <i class="icofont-linkedin"></i>
              </a>
            </div>
          </div>
        </section> */}
        <div
          id="header-sticky-wrapper"
          class="sticky-wrapper fixed-top"
          style={{ height: "70px" }}
        >
          <div
            //   <header
            id="header"
            class="d-flex "
            style={{
              // width: "360px",
              // position: "fixed",
              //   top: "0px",
              zIndex: 50,
            }}
          >
            <div class="container d-flex d-flex justify-content-center align-items-center">
              <div class="logo mr-auto">
                <div>
                  <a href="/">
                    {/* <span>Eterna</span> */}
                    <img
                      src={logo}
                      alt="userLogo"
                      style={{
                        height: "100px",
                      }}
                    />
                  </a>
                </div>
                <button
                  type="button"
                  class="mobile-nav-toggle d-lg-none"
                  style={{ color: "rgb(5 143 206)" }}
                  onClick={() => this.openModal()}
                >
                  <i
                    class={
                      this.state.showModal === false &&
                      "icofont-navigation-menu"
                    }
                    style={{ color: "rgb(5 143 206)" }}
                  ></i>
                </button>
                {/* {this.state.showModal === false ? (
                  <button
                    type="button"
                    class="mobile-nav-toggle d-lg-none"
                    style={{ color: "rgb(5 143 206)" }}
                    onClick={() => this.openModal()}
                  >
                
                    <i
                      class={
                        this.state.showModal === true
                          ? "icofont-close"
                          : "icofont-navigation-menu"
                      }
                      style={{ color: "rgb(5 143 206)" }}
                    ></i>
                  </button>
                ) : (
                  <button
                    type="button"
                    class="mobile-nav-toggle d-lg-none"
                    style={{ color: "rgb(5 143 206)" }}
                    onClick={() => this.hideModal()}
                  >
                 
                    <i
                      class={this.state.showModal === true && "icofont-close"}
                      style={{ color: "#fff" }}
                    ></i>
                  </button>
                )} */}
                {/* Uncomment below if you prefer to use an image logo --> */}
                {/* <a href="index.html">
                  <img src="assets/img/logo.png" alt="" class="img-fluid" />
                </a> */}
              </div>

              <nav class="nav-menu d-none d-lg-block flxed-top">
                <ul>
                  {/* <li>
                    <a href="/">Home</a>
                  </li> */}
                  <li>
                    <a href="/firm-overview">{I18n.t("navbar.navHeading1")}</a>
                  </li>

                  {/* <li class="drop-down">
                    <a href="#">Who We Are</a>
                    <ul>
                      <li>
                        <a href="about.html">Firm Overview</a>
                      </li>
                      <li>
                        <a href="team.html">Client Centered Approach</a>
                      </li>
                      <li>
                        <a href="team.html">Culture & Core Values</a>
                      </li>
                      <li>
                        <a href="team.html">International Reach</a>
                      </li> */}

                  {/* <li class="drop-down">
                        <a href="#">Drop Down 2</a>
                        <ul>
                          <li>
                            <a href="#">Deep Drop Down 1</a>
                          </li>
                          <li>
                            <a href="#">Deep Drop Down 2</a>
                          </li>
                          <li>
                            <a href="#">Deep Drop Down 3</a>
                          </li>
                          <li>
                            <a href="#">Deep Drop Down 4</a>
                          </li>
                          <li>
                            <a href="#">Deep Drop Down 5</a>
                          </li>
                        </ul>
                      </li> */}
                  {/* </ul>
                  </li> */}
                  <li class="drop-down">
                    <a href="#"> {I18n.t("navbar.navHeading2")}</a>
                    <ul>
                      <li>
                        <a href="/banking-and-finance">
                          {I18n.t("home.servicesubHeading1")}
                        </a>
                      </li>
                      <li>
                        <a href="/company-secretarial">
                          {I18n.t("home.servicesubHeading2")}
                        </a>
                      </li>
                      <li>
                        <a href="/commercial">
                          {" "}
                          {I18n.t("home.servicesubHeading3")}
                        </a>
                      </li>
                      <li>
                        <a href="/corporate">
                          {" "}
                          {I18n.t("home.servicesubHeading4")}
                        </a>
                      </li>
                      <li>
                        <a href="/dispute-resolution-litigation">
                          {I18n.t("home.servicesubHeading5")}
                        </a>
                      </li>
                      <li>
                        <a href="/employment-and-labour">
                          {I18n.t("home.servicesubHeading6")}
                        </a>
                      </li>
                      <li>
                        <a href="/intellectual-property">
                          {I18n.t("home.servicesubHeading7")}
                        </a>
                      </li>
                      <li>
                        <a href="/private-client-services">
                          {I18n.t("home.servicesubHeading8")}
                        </a>
                      </li>
                      <li>
                        <a href="/property-&-real-estate">
                          {I18n.t("home.servicesubHeading9")}
                        </a>
                      </li>
                      <li>
                        <a href="/family-law">
                          {I18n.t("home.servicesubHeading10")}
                        </a>
                      </li>
                      <li>
                        <a href="/taxation">
                          {I18n.t("home.servicesubHeading11")}
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="/our-professionals">
                      {" "}
                      {I18n.t("navbar.navHeading3")}
                    </a>
                  </li>

                  {/* <li class="drop-down">
                    <a href="#"> {I18n.t("navbar.navHeading4")}</a>
                    <ul>
                      <li>
                        <a href="#">Deep Drop Down 1</a>
                      </li>
                      <li>
                        <a href="#">Deep Drop Down 2</a>
                      </li>
                      <li>
                        <a href="#">Deep Drop Down 3</a>
                      </li>
                      <li>
                        <a href="#">Deep Drop Down 4</a>
                      </li>
                      <li>
                        <a href="#">Deep Drop Down 5</a>
                      </li>
                    </ul>
                  </li> */}
                  <li>
              <a href="/careers"> {I18n.t("navbar.navHeading5")}</a>
            </li>

                  <li>
                    <a href="/contact"> {I18n.t("navbar.navHeading6")}</a>
                  </li>
                  <li class="drop-down">
                    <a href="#"> {I18n.t("navbar.navHeading7")}</a>
                    <ul>
                      <li>
                        {/* <a onClick={() => this.props.eng_lang("en")}>English</a> */}
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => this.handleChange("en")}
                        >
                          English
                        </a>
                      </li>
                      <li>
                        {/* <a onClick={() => this.props.chin_lang("ch")}> */}
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => this.handleChange("ch")}
                        >
                          Chinese
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        {/* Mobile Navigation */}

        {/* <nav class="mobile-nav d-lg-none"> */}
        <nav class="mobile-nav d-lg-block">
          <ul>
            <li>
              <button
                type="button"
                class="mobile-nav-toggle d-lg-none"
                style={{ color: "#fff" }}
                onClick={() => this.hideModal()}
              >
                {/* icofont-close */}
                <i
                  class={this.state.showModal === true && "icofont-close"}
                  style={{ color: "#fff !important" }}
                ></i>
              </button>
            </li>
            <li>
              <a href="/firm-overview">{I18n.t("navbar.navHeading1")}</a>
            </li>
            <li class={this.state.dropD ? "drop-down active" : "drop-down "}>
              <a onClick={() => this.setState({ dropD: !this.state.dropD })}>
                {" "}
                {I18n.t("navbar.navHeading2")}
              </a>
              <ul style={{ display: this.state.dropD ? "block " : "none" }}>
                <li>
                  <a href="/banking-and-finance">
                    {I18n.t("home.servicesubHeading1")}
                  </a>
                </li>
                <li>
                  <a href="/company-secretarial">
                    {I18n.t("home.servicesubHeading2")}
                  </a>
                </li>
                <li>
                  <a href="/commercial"> {I18n.t("home.servicesubHeading3")}</a>
                </li>
                <li>
                  <a href="/corporate"> {I18n.t("home.servicesubHeading4")}</a>
                </li>
                <li>
                  <a href="/dispute-resolution-litigation">
                    {I18n.t("home.servicesubHeading5")}
                  </a>
                </li>
                <li>
                  <a href="/employment-and-labour">
                    {I18n.t("home.servicesubHeading6")}
                  </a>
                </li>
                <li>
                  <a href="/intellectual-property">
                    {I18n.t("home.servicesubHeading7")}
                  </a>
                </li>
                <li>
                  <a href="/private-client-services">
                    {I18n.t("home.servicesubHeading8")}
                  </a>
                </li>
                <li>
                  <a href="/property-&-real-estate">
                    {I18n.t("home.servicesubHeading9")}
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href="/our-professionals"> {I18n.t("navbar.navHeading3")}</a>
            </li>

            <li class={this.state.dropD1 ? "drop-down active" : "drop-down "}>
              <a onClick={() => this.setState({ dropD1: !this.state.dropD1 })}>
                {" "}
                {I18n.t("navbar.navHeading4")}
              </a>
              <ul style={{ display: this.state.dropD1 ? "block " : "none" }}>
                <li>
                  <a href="#">Deep Drop Down 1</a>
                </li>
                <li>
                  <a href="#">Deep Drop Down 2</a>
                </li>
                <li>
                  <a href="#">Deep Drop Down 3</a>
                </li>
                <li>
                  <a href="#">Deep Drop Down 4</a>
                </li>
                <li>
                  <a href="#">Deep Drop Down 5</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="/careers"> {I18n.t("navbar.navHeading5")}</a>
            </li>

            <li>
              <a href="/contact"> {I18n.t("navbar.navHeading6")}</a>
            </li>
            <li class={this.state.dropD2 ? "drop-down active" : "drop-down "}>
              <a onClick={() => this.setState({ dropD2: !this.state.dropD2 })}> {I18n.t("navbar.navHeading7")}</a>
              <ul style={{ display: this.state.dropD2 ? "block " : "none" }}>
                <li>
                  {/* <a onClick={() => this.props.eng_lang("en")}>English</a> */}
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => this.handleChange("en")}
                  >
                    English
                  </a>
                </li>
                <li>
                  {/* <a onClick={() => this.props.chin_lang("ch")}> */}
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => this.handleChange("ch")}
                  >
                    Chinese
                  </a>
                </li>
              </ul>
            </li>
            {/* <li class="drop-down">
              <a href="#">About</a>
              <ul>
                <li>
                  <a href="about.html">About Us</a>
                </li>
                <li>
                  <a href="team.html">Team</a>
                </li>
                <li class="drop-down">
                  <a href="#">Drop Down 2</a>
                  <ul>
                    <li>
                      <a href="#">Deep Drop Down 1</a>
                    </li>
                    <li>
                      <a href="#">Deep Drop Down 2</a>
                    </li>
                    <li>
                      <a href="#">Deep Drop Down 3</a>
                    </li>
                    <li>
                      <a href="#">Deep Drop Down 4</a>
                    </li>
                    <li>
                      <a href="#">Deep Drop Down 5</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li> */}
            {/* <li class="active">
              <a href="services.html">Services</a>
            </li>
            <li>
              <a href="portfolio.html">Portfolio</a>
            </li>
            <li>
              <a href="pricing.html">Pricing</a>
            </li>
            <li>
              <a href="blog.html">Blog</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li> */}
          </ul>
        </nav>

        <div
          class="mobile-nav-overly"
          style={{ display: this.state.showModal === false ? "none" : "block" }}
        ></div>
      </div>
    );
  }
}

// export default Navbar;
// export default Todo;
const mapStateToProps = (state) => {
  // console.log(state);
  return {
    getReduxData: state.data,
    // userR: state.user,
    // user: state.user,
    // userfb: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // eng_lang: (data) => dispatch(english_lang(data)),
    // chin_lang: (data) => dispatch(chinese_lang(data)),
    setLanguage: (language) => dispatch(setLocale(language)),
  };
};
// export default withStyles(styles)(Login);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
