import React, { Component } from "react";

// import firebase from './firebase'
// import logo from "../../assets/userLogo.png";
// import pix1 from "../../assets/pix1.jpg";
// import pix2 from "../../assets/pix2.jpg";
// import Navbar from "../../config/Navbar";
var I18n = require("react-redux-i18n").I18n;
export default class Footer extends Component {
  render() {
    return (
      <div>
        <footer id="footer">
          {/* <div class="footer-newsletter">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <h4>Our Newsletter</h4>
                  <p>
                    Tamen quem nulla quae legam multos aute sint culpa legam
                    noster magna
                  </p>
                </div>
                <div class="col-lg-6">
                  <form action="" method="post">
                    <input type="email" name="email" />
                    <input type="submit" value="Subscribe" />
                  </form>
                </div>
              </div>
            </div>
          </div> */}

          <div class="footer-top">
            <div class="container">
              <div class="row">
                <div class="col-lg-3 col-md-6 footer-links">
                  <h4> {I18n.t("aboutData.heading2")}</h4>
                  <ul>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a href="/banking-and-finance">
                        {" "}
                        {I18n.t("home.servicesubHeading1")}
                      </a>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a href="/company-secretarial">
                        {I18n.t("home.servicesubHeading2")}
                      </a>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a href="/commercial">
                        {" "}
                        {I18n.t("home.servicesubHeading3")}
                      </a>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a href="/corporate">
                        {" "}
                        {I18n.t("home.servicesubHeading4")}
                      </a>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a href="/dispute-resolution-litigation">
                        {I18n.t("home.servicesubHeading5")}
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-3 col-md-6 footer-links">
                  <h4> {I18n.t("aboutData.heading3")}</h4>
                  <ul>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a href="/employment-and-labour">
                        {" "}
                        {I18n.t("home.servicesubHeading6")}
                      </a>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a href="/intellectual-property">
                        {" "}
                        {I18n.t("home.servicesubHeading7")}
                      </a>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a href="/private-client-services">
                        {I18n.t("home.servicesubHeading8")}
                      </a>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a href="/property-&-real-estate">
                        {I18n.t("home.servicesubHeading9")}
                      </a>
                    </li>{" "}
                    <li>
                      {" "}
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a href="/property-&-real-estate">
                        {I18n.t("home.servicesubHeading10")}
                      </a>
                    </li>
                    {/* <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a href="#">Graphic Design</a>
                    </li> */}
                  </ul>
                </div>

                <div class="col-lg-3 col-md-6 footer-contact">
                  <h4>{I18n.t("aboutData.content2")}</h4>
                  <p>
                    201, Asad chambers, <br />
                    near Sindh High Court, Saddar,
                    <br />
                    Karachi, Pakistan <br />
                    <br />
                    <strong>{I18n.t("aboutData.content5")}:</strong> +92 (0) 21
                    38887114 - 5 <br />
                    {/* <strong>{I18n.t("aboutData.content5")}:</strong> +92 (0) 21
                    38887115
                    <br /> */}
                    <strong>{I18n.t("aboutData.content6")}:</strong>{" "}
                    info@sayyidandshaykh.com
                    <br />
                  </p>
                </div>

                <div class="col-lg-3 col-md-6 footer-info">
                  <h3> {I18n.t("aboutData.heading1")}</h3>
                  <p>{I18n.t("aboutData.content1")}</p>
                  {/* <div class="social-links mt-3">
                    <a href="#" class="twitter">
                      <i class="bx bxl-twitter"></i>
                    </a>
                    <a href="#" class="facebook">
                      <i class="bx bxl-facebook"></i>
                    </a>
                    <a href="#" class="instagram">
                      <i class="bx bxl-instagram"></i>
                    </a>
                    <a href="#" class="google-plus">
                      <i class="bx bxl-skype"></i>
                    </a>
                    <a href="#" class="linkedin">
                      <i class="bx bxl-linkedin"></i>
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="copyright">&copy; {I18n.t("aboutData.content3")}</div>
            <div class="credits">
              {I18n.t("aboutData.content4")}{" "}
              <a href="https://eight-1-eight.web.app/" target="_blank">
                Eight-1-Eight
              </a>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
