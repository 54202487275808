import React, { Component } from "react";

// import firebase from './firebase'
// import logo from "../../assets/userLogo.png";
// import pix1 from "../../assets/pix1.jpg";
// import pix2 from "../../assets/pix2.jpg";
import Navbar from "../../config/Navbar";
import Footer from "../../config/Footer";
var I18n = require("react-redux-i18n").I18n;
export default class BankFinance extends Component {
  handleOnHome = (event) => {
    //.current is verification that your element has rendered
    // window.scrollTo(0, this.myRefHome.current.offsetTop);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };
  render() {
    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />

          <main id="main">
            {/* <!-- ======= Breadcrumbs ======= --> */}
            <section id="breadcrumbs" class="breadcrumbs">
              <div class="container ">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li> {this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
              <div class="container text-center">
                <h1>
                  {" "}
                  {I18n.t("home.servicesubHeading6").toUpperCase()}
                </h1>
              </div>
            </section>

            {/* <!-- End Breadcrumbs --> */}

            {/* <!-- ======= About Section ======= --> */}
            <section id="about" class="about">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12 pt-4 pt-lg-0 content">
                    <p class="text-justify">
                      {I18n.t("employmentLabour.content1")}
                    </p>
                    <p class="text-justify">
                      {I18n.t("employmentLabour.content2")}
                    </p>

                    <ul style={{ listStyle: "none" }}>
                      <li style={{ fontSize: "16px" }}>
                        <i
                          class="icofont-check-circled"
                          style={{
                            fontSize: "16px",
                            paddingRight: "4px",
                            color: " #0690ce",
                          }}
                        ></i>
                        {I18n.t("employmentLabour.contentList1")}
                      </li>
                      <li style={{ fontSize: "16px" }}>
                        <i
                          class="icofont-check-circled"
                          style={{
                            fontSize: "16px",
                            paddingRight: "4px",
                            color: " #0690ce",
                          }}
                        ></i>
                        {I18n.t("employmentLabour.contentList2")}
                      </li>
                      <li style={{ fontSize: "16px" }}>
                        <i
                          class="icofont-check-circled"
                          style={{
                            fontSize: "16px",
                            paddingRight: "4px",
                            color: " #0690ce",
                          }}
                        ></i>
                        {I18n.t("employmentLabour.contentList3")}
                      </li>
                      <li style={{ fontSize: "16px" }}>
                        <i
                          class="icofont-check-circled"
                          style={{
                            fontSize: "16px",
                            paddingRight: "4px",
                            color: " #0690ce",
                          }}
                        ></i>
                        {I18n.t("employmentLabour.contentList4")}
                      </li>
                      <li style={{ fontSize: "16px" }}>
                        <i
                          class="icofont-check-circled text-justify"
                          style={{
                            fontSize: "16px",
                            paddingRight: "4px",
                            color: " #0690ce",
                          }}
                        ></i>
                        {I18n.t("employmentLabour.contentList5")}
                      </li>
                      <li style={{ fontSize: "16px" }}>
                        <i
                          class="icofont-check-circled"
                          style={{
                            fontSize: "16px",
                            paddingRight: "4px",
                            color: " #0690ce",
                          }}
                        ></i>
                        {I18n.t("employmentLabour.contentList6")}
                      </li>
                    </ul>
                    <p class="text-justify">
                      {I18n.t("employmentLabour.content3")}
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
        <div>
          <Footer />
        </div>

        <a onClick={() => this.handleOnHome()} class="back-to-top">
          <i class="icofont-simple-up"></i>
        </a>
      </div>
    );
  }
}
