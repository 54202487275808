import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
/************************* USERS SECTION ******************************/
import {
  Home,
  BankingFinance,
  Commercial,
  CompanySecretarial,
  Corporate,
  EmploymentLabour,
  PropertyEstate,
  privateClientServices,
  intellectualProperty,
  disputeResolution,
  ourProfessionals,
  firmOverview,
  contact,
  Careers,
  familyLaw,
  Taxation,
} from "./index";
/************************* USERS SECTION ******************************/
/************************* ADMIN SECTION ******************************/
import { Login, Dashboard, Users, Account, AddCareers } from "./index";
/************************* ADMIN SECTION ******************************/
import ScrollToTop from "react-router-scroll-top";
/* ================ Private Routes ================ */
import PrivateRoute from "./PrivateRoute";

class Routes extends Component {
  render() {
    const { user } = this.props;
    console.log(user);
    return (
      <Router>
        <ScrollToTop>
          <Switch>
            <Route path="/" exact={true} component={Home} />
            {/* <Route path="/" exact={true} component={Users} /> */}

            <Route path="/Login" component={Login} />

            <Route path="/banking-and-finance" component={BankingFinance} />
            <Route path="/commercial" component={Commercial} />
            <Route path="/company-secretarial" component={CompanySecretarial} />
            <Route path="/corporate" component={Corporate} />
            <Route path="/property-&-real-estate" component={PropertyEstate} />
            <Route path="/employment-and-labour" component={EmploymentLabour} />
            <Route
              path="/private-client-services"
              component={privateClientServices}
            />
            <Route
              path="/intellectual-property"
              component={intellectualProperty}
            />
            <Route
              path="/dispute-resolution-litigation"
              component={disputeResolution}
            />
            <Route path="/family-law" component={familyLaw} />
            <Route path="/taxation" component={Taxation} />
            <Route path="/our-professionals" component={ourProfessionals} />
            <Route path="/firm-overview" component={firmOverview} />
            <Route path="/contact" component={contact} />
            <Route path="/careers" component={Careers} />

            {/* <Route path="*" component={NoMatch} /> */}
            <PrivateRoute path="/users" component={Users} authed={user.user} />
            <PrivateRoute
              path="/add-careers"
              component={AddCareers}
              authed={user.user}
            />
            <PrivateRoute
              path="/account"
              component={Account}
              authed={user.user}
            />
            <PrivateRoute
              path="/dashboard"
              component={Dashboard}
              authed={user.user}
            />
          </Switch>
        </ScrollToTop>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("state.user on route compoinent", state.reducers);
  return {
    user: state.reducers,
  };
};

// export default withStyles(styles)(Login);
export default connect(mapStateToProps, null)(Routes);
