import React, { Component } from "react";

// import firebase from './firebase'
// import logo from "../../assets/userLogo.png";
// import pix1 from "../../assets/pix1.jpg";
// import pix2 from "../../assets/pix2.jpg";
import Navbar from "../../config/Navbar";
import Footer from "../../config/Footer";
var I18n = require("react-redux-i18n").I18n;
export default class Commerical extends Component {
  handleOnHome = (event) => {
    //.current is verification that your element has rendered
    // window.scrollTo(0, this.myRefHome.current.offsetTop);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };
  render() {
    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />
          <Navbar />
          <main id="main">
            {/* <!-- ======= Breadcrumbs ======= --> */}
            <section id="breadcrumbs" class="breadcrumbs">
              <div class="container ">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li> {this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
              <div class="container text-center">
                <h1> {I18n.t("home.servicesubHeading3").toUpperCase()}</h1>
              </div>
            </section>

            {/* <!-- End Breadcrumbs --> */}

            {/* <!-- ======= About Section ======= --> */}
            <section id="about" class="about">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12 pt-4 pt-lg-0 content">
                    <p class="text-justify">{I18n.t("commerical.content1")}</p>
                    <p class="text-justify">{I18n.t("commerical.content2")}</p>
                    <p class="text-justify">{I18n.t("commerical.content3")}</p>

                    <ul>
                      <li>
                        {" "}
                        <i class="icofont-check-circled"></i>{" "}
                        {I18n.t("commerical.contentList1")}
                      </li>
                      <li>
                        {" "}
                        <i class="icofont-check-circled"></i>{" "}
                        {I18n.t("commerical.contentList2")}
                      </li>
                      <li>
                        {" "}
                        <i class="icofont-check-circled"></i>{" "}
                        {I18n.t("commerical.contentList3")}
                      </li>
                      <li>
                        {" "}
                        <i class="icofont-check-circled"></i>{" "}
                        {I18n.t("commerical.contentList4")}
                      </li>
                      <li>
                        {" "}
                        <i class="icofont-check-circled"></i>{" "}
                        {I18n.t("commerical.contentList5")}
                      </li>
                      <li>
                        {" "}
                        <i class="icofont-check-circled"></i>{" "}
                        {I18n.t("commerical.contentList6")}
                      </li>
                      <li>
                        {" "}
                        <i class="icofont-check-circled"></i>{" "}
                        {I18n.t("commerical.contentList7")}
                      </li>
                      <li>
                        {" "}
                        <i class="icofont-check-circled"></i>{" "}
                        {I18n.t("commerical.contentList8")}
                      </li>
                      <li>
                        {" "}
                        <i class="icofont-check-circled"></i>{" "}
                        {I18n.t("commerical.contentList9")}
                      </li>
                      <li>
                        {" "}
                        <i class="icofont-check-circled"></i>{" "}
                        {I18n.t("commerical.contentList10")}
                      </li>
                    </ul>
                    <p class="text-justify">{I18n.t("commerical.content4")}</p>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
        <div>
          <Footer />
        </div>

        <a onClick={() => this.handleOnHome()} class="back-to-top">
          <i class="icofont-simple-up"></i>
        </a>
      </div>
    );
  }
}
