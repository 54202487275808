import React from "react";
import Navbar from "./config/Routes";
import "./assets/css/style.css";

import { store, persistor } from './store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
 
function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Navbar />
            </PersistGate>
        </Provider>
    );
}

export default App;
