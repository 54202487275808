// const english_lang = (user) => {
//   return {
//     type: "SET_LANGUAGE",
//     data: user,
//   };
// };
const set_lang = (user) => {
  return {
    type: "SET_LANGUAGE",
    data: user,
  };
};

const update_user = (user) => {
  return {
    type: "SET_USER",
    data: user,
  };
};

const remove_user = () => {
  return {
    type: "REMOVE_USER",
    data: null,
  };
};

// const chinese_lang = (user) => {
//   return {
//     type: "CH_LANG",
//     data: user,
//   };
// };

export { update_user, remove_user, set_lang };
