import React, { Component } from "react";
import Navbar from "../../config/NavAdmin";
import { update_user, remove_user } from "../../store/actions/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import firebase from "../../config/firebase";
class Profile extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      type: "password",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleClickPass = () => {
    // this.setState(({ type }) => ({
    this.setState({
      type: !this.state.type,

      // }));
    });
  };

  handleChange(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }
  handleOnHome = (event) => {
    //.current is verification that your element has rendered
    // window.scrollTo(0, this.myRefHome.current.offsetTop);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };
  async componentDidMount() {
    await this.getData();
    // this.authListener();
    // this.progressUpdate();
    // this.intervalID = setInterval(() => this.tick(), 1000);
  }

  getData = () => {
    const {
      user,
      match: { params },
    } = this.props;
    console.log(this.props);
    var messagesRef = firebase.database().ref("Admin/" + user.user.user.uid);
    messagesRef.on("value", (snapshot) => {
      let message = snapshot.val();
      console.log("message", message);
      if (message.userId === user.user.user.uid) {
        this.setState({
          email: message.email,
          firstName: message.firstName,
          lastName: message.lastName,
          password: message.password,
          userId: message.userId,
        });
      }
    });
  };

  accountUpdate = () => {
    const { user } = this.props;
    const { email, password, userId, lastName, firstName } = this.state;
    var obj = {
      email,
      firstName,
      lastName,
      password,
      userId: user.user.user.uid,
      updateAt: new Date().toLocaleString(),
    };
    // console.log(user.user.user.uid)
    // this.props.history.push("/Login");
    firebase
      .database()
      .ref("Admin/" + user.user.user.uid)
      .update(obj)
      .then(() => {
        var user = firebase.auth().currentUser;
        //   var newPassword = getASecureRandomPassword();
        var newPassword = password;

        user
          .updatePassword(newPassword)
          .then(() => {
            // Update successful.
            console.log("success");
            firebase
              .auth()
              .signOut()
              .then(() => {
                this.props.logout_user();
                this.props.history.push("/Login");

                // Sign-out successful.
              });
            //   setTimeout(function () {
            //     history.push("/Login");
            //   }, 2000);
          })
          .catch(function (error) {
            // An error happened.
            console.log("Err", error.message);
          });
        // this.setState({
        //   loaderToggle: !this.state.loaderToggle,
        //   openSnack: true,
        // });
      })
      .catch((error) => {
        // var errorCode = error.code;
        var errorMessage = error.message;
        console.error("Error writing document: ", `${error}`);
        // alert(error);
        this.setState({
          message: errorMessage,
        });
      });
  };
  render() {
    const { user } = this.props;
    const { email, password, userId, lastName, firstName } = this.state;

    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />

          <main id="main">
            {/* <!-- ======= Breadcrumbs ======= --> */}
            <section id="breadcrumbs" class="breadcrumbs">
              <div class="container ">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li> {this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
              <div class="container text-center">
                {/* <h1> {I18n.t("home.servicesubHeading3").toUpperCase()}</h1> */}
                <h1>Account</h1>
              </div>
            </section>

            {/* <!-- End Breadcrumbs --> */}

            <section id="doctors" class="doctors">
              <div class="container">
                {" "}
                <div class="row">
                  <div class="form-group col-md-6">
                    <label class="small mb-1" for="inputEmailAddress">
                      First Name
                    </label>
                    <input
                      class="form-control py-4"
                      id="inputEmailAddress"
                      type="text"
                      placeholder="Enter First Name"
                      name="firstName"
                      value={firstName}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="small mb-1" for="inputEmailAddress">
                      Last Name
                    </label>
                    <input
                      class="form-control py-4"
                      id="inputEmailAddress"
                      type="text"
                      placeholder="Enter Last Name"
                      name="lastName"
                      value={lastName}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label class="small mb-1" for="inputEmailAddress">
                      Email
                    </label>
                    <input
                      class="form-control py-4"
                      id="inputEmailAddress"
                      type="email"
                      placeholder="Enter Email"
                      name="email"
                      value={email}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="small mb-1" for="inputEmailAddress">
                      Password
                    </label>
                    <div>
                      <input
                        // style={{ position: "absolute" }}
                        class="form-control py-4"
                        id="inputEmailAddress"
                        type={this.state.type ? "password" : "text"}
                        placeholder="Enter Password"
                        name="password"
                        value={password}
                        onChange={this.handleChange}
                      />
                      <div class="ml-4">
                        <input
                          class="form-check-input "
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          onClick={() => this.handleClickPass()}
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          {this.state.type ? "Show  Password" : "Hide Password"}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="btn btn-primary float-right"
                  onClick={() => this.accountUpdate()}
                >
                  Update
                </button>
              </div>
            </section>
          </main>
        </div>

        <a onClick={() => this.handleOnHome()} class="back-to-top">
          <i class="icofont-simple-up"></i>
        </a>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.reducers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    store_user: (userlogin) => dispatch(update_user(userlogin)),

    logout_user: () => dispatch(remove_user()),
  };
};
// export default withStyles(styles)(Login);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
