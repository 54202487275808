import React, { Component } from "react";

// import firebase from './firebase'
// import logo from "../../assets/userLogo.png";
// import pix1 from "../../assets/pix1.jpg";
// import pix2 from "../../assets/pix2.jpg";
import Navbar from "../../config/Navbar";
import Footer from "../../config/Footer";
var I18n = require("react-redux-i18n").I18n;
export default class BankFinance extends Component {
  handleOnHome = (event) => {
    //.current is verification that your element has rendered
    // window.scrollTo(0, this.myRefHome.current.offsetTop);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };
  render() {
    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />
          <Navbar />
          <main id="main">
            {/* <!-- ======= Breadcrumbs ======= --> */}
            <section id="breadcrumbs" class="breadcrumbs">
              <div class="container ">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li> {this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
              <div class="container text-center">
                <h1>
                  {" "}
                  {I18n.t("home.servicesubHeading4").toUpperCase()}
                </h1>
              </div>
            </section>

            {/* <!-- End Breadcrumbs --> */}

            {/* <!-- ======= About Section ======= --> */}
            <section id="about" class="about">
              <div class="container">
                <div class="row">
                  {/* <div class="col-lg-6">
                    <img src="assets/img/about.jpg" class="img-fluid" alt="" />
                  </div> */}
                  <div class="col-lg-12 pt-4 pt-lg-0 content">
                    {/* <h3>
                      Voluptatem dignissimos provident quasi corporis voluptates
                      sit assumenda.
                    </h3> */}
                    {/* <p class="font-italic">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p> */}

                    <p class="text-justify">
                    {I18n.t("corporate.content1")}
             
                    </p>
                    <p class="text-justify">
                    {I18n.t("corporate.content2")}
                    </p>
                    <p class="text-justify">
                    {I18n.t("corporate.content3")}
                    </p>

                    <ul>
                      <li>                        <i class="icofont-check-circled"></i> {I18n.t("corporate.contentList1")}   </li>
                      <li>                        <i class="icofont-check-circled"></i> {I18n.t("corporate.contentList2")}   </li>
                      <li>                        <i class="icofont-check-circled"></i> {I18n.t("corporate.contentList3")}   </li>
                      <li>                        <i class="icofont-check-circled"></i> {I18n.t("corporate.contentList4")}   </li>
                      <li>                        <i class="icofont-check-circled"></i> {I18n.t("corporate.contentList5")}   </li>
                      <li>                        <i class="icofont-check-circled"></i> {I18n.t("corporate.contentList6")}   </li>
                      <li>                        <i class="icofont-check-circled"></i> {I18n.t("corporate.contentList7")}   </li>
                      <li>                        <i class="icofont-check-circled"></i> {I18n.t("corporate.contentList8")}   </li>
                      <li>                        <i class="icofont-check-circled"></i> {I18n.t("corporate.contentList9")}   </li>
                    
                    </ul>
                    <p class="text-justify">
                    {I18n.t("corporate.content4")}
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section id="clients" class="clients">
              <div class="container">
                <div class="accordion" id="accordionExample">
                  <div class="card">
                    <div
                      class="card-header1 "
                      id="headingOne"
                      style={{ backgroundColor: "#0690ce" }}
                    >
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          style={{ color: "white", textDecoration: "none" }}
                        >
                     {I18n.t("corporate.heading1")}
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne"
                      class="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        <p class="text-justify">
                        {I18n.t("corporate.heading1Content1")}
                        </p>
                        <p class="text-justify">
                        {I18n.t("corporate.heading1Content2")}
                        </p>

                        <ul style={{ listStyle: "none" }}>
                          <li style={{ fontSize: "16px" }}>
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                       {I18n.t("corporate.heading1ContentList1")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                           {I18n.t("corporate.heading1ContentList2")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                           {I18n.t("corporate.heading1ContentList3")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                    {I18n.t("corporate.heading1ContentList4")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                          {I18n.t("corporate.heading1ContentList5")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                       {I18n.t("corporate.heading1ContentList6")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                          {I18n.t("corporate.heading1ContentList7")}
                          </li>
                        </ul>
                        <p class="text-justify">
                        {I18n.t("corporate.heading1Content3")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div
                      class="card-header1 "
                      id="headingTwo"
                      style={{ backgroundColor: "#0690ce" }}
                    >
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          style={{ color: "white", textDecoration: "none" }}
                        >
                  {I18n.t("corporate.heading2")}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      class="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        <p class="text-justify">
                        {I18n.t("corporate.heading2Content1")}
                        </p>
                        <p class="text-justify">
                        {I18n.t("corporate.heading2Content2")}
                        </p>
                        <p class="text-justify">
                        {I18n.t("corporate.heading2Content3")}
                        </p>
                        <ul style={{ listStyle: "none" }}>
                          <li style={{ fontSize: "16px" }}>
                            {" "}
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                            {I18n.t("corporate.heading2ContentList1")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            {" "}
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                      {I18n.t("corporate.heading2ContentList2")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            {" "}
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                         {I18n.t("corporate.heading2ContentList3")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            {" "}
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                         {I18n.t("corporate.heading2ContentList4")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            {" "}
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                          {I18n.t("corporate.heading2ContentList5")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            {" "}
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                     {I18n.t("corporate.heading2ContentList6")}
                          </li>
                        </ul>
                        <p class="text-justify">
                        {I18n.t("corporate.heading2Content4")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div
                      class="card-header1 "
                      id="headingThree"
                      style={{ backgroundColor: "#0690ce" }}
                    >
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          style={{ color: "white", textDecoration: "none" }}
                        >
                      {I18n.t("corporate.heading3")}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseThree"
                      class="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        <p class="text-justify">
                        {I18n.t("corporate.heading3Content1")}
                        </p>
                        <ul style={{ listStyle: "none" }}>
                          <li style={{ fontSize: "16px" }}>
                            {" "}
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                             {I18n.t("corporate.heading3contentlist1")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            {" "}
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                         {I18n.t("corporate.heading3contentlist2")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            {" "}
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                            {I18n.t("corporate.heading3contentlist3")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            {" "}
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                           {I18n.t("corporate.heading3contentlist4")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            {" "}
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                            {I18n.t("corporate.heading3contentlist5")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            {" "}
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                            {I18n.t("corporate.heading3contentlist6")}
                          </li>
                          <li style={{ fontSize: "16px" }}>
                            {" "}
                            <i
                              class="icofont-check-circled"
                              style={{
                                fontSize: "16px",
                                paddingRight: "4px",
                                color: " #0690ce",
                              }}
                            ></i>
                             {I18n.t("corporate.heading3contentlist7")}
                          </li>
                        </ul>
                        <p class="text-justify">
                        {I18n.t("corporate.heading3Content2")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div
                      class="card-header1 "
                      id="headingFour"
                      style={{ backgroundColor: "#0690ce" }}
                    >
                      <h2 class="mb-0"> 
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                          style={{ color: "white", textDecoration: "none" }}
                        >
                          {I18n.t("corporate.heading4")}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseFour"
                      class="collapse"
                      aria-labelledby="headingFour"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        <p class="text-justify">
                        {I18n.t("corporate.heading4Content1")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
        <div>
          <Footer />
        </div>

        <a onClick={() => this.handleOnHome()} class="back-to-top">
          <i class="icofont-simple-up"></i>
        </a>
      </div>
    );
  }
}
