import React, { Component } from "react";
// import {} from "react-router"; // Note this extra line
// import { english_lang, chinese_lang, set_lang } from "../store/actions/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { remove_user } from "../store/actions/actions";
import { setLocale } from "react-redux-i18n";

/************************* USERS SECTION ******************************/
import logo from "../assets/userLogo.png";
import firebase from "../config/firebase";
class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
  }

  logout = () => {
    const { history } = this.props;
    firebase
      .auth()
      .signOut()
      .then(() => {
        history.push("/Login");
        this.hideModal();
        // Sign-out successful.
      })
      .catch(function (error) {
        // An error happened.
        console.log(error.message);
      });
    this.props.logout_user();
  };

  openModal = (event) => {
    document.body.classList.add("mobile-nav-active");
    this.setState({ showModal: true });
  };
  hideModal = (event) => {
    document.body.classList.remove("mobile-nav-active");
    this.setState({ showModal: false });
  };
  render() {
    const { user } = this.props;
    // console.log("user", user.user.user.email);
    return (
      <div>
      
        <div
          id="header-sticky-wrapper"
          class="sticky-wrapper fixed-top"
          style={{ height: "70px" }}
        >
          <div
            //   <header
            id="header"
            class="d-flex "
            style={{
              // width: "360px",
              // position: "fixed",
              //   top: "0px",
              zIndex: 50,
            }}
          >
            <div class="container d-flex d-flex justify-content-center align-items-center">
              <div class="logo mr-auto">
                <div>
                  <a href="/">
                    {/* <span>Eterna</span> */}
                    <img
                      src={logo}
                      alt="userLogo"
                      style={{
                        height: "100px",
                      }}
                    />
                  </a>
                </div>
                <button
                  type="button"
                  class="mobile-nav-toggle d-lg-none"
                  style={{ color: "rgb(5 143 206)" }}
                  onClick={() => this.openModal()}
                >
                  <i
                    class={
                      this.state.showModal === false &&
                      "icofont-navigation-menu"
                    }
                    style={{ color: "rgb(5 143 206)" }}
                  ></i>
                </button>
                {/* Uncomment below if you prefer to use an image logo --> */}
                {/* <a href="index.html">
                  <img src="assets/img/logo.png" alt="" class="img-fluid" />
                </a> */}
              </div>

              <nav class="nav-menu d-none d-lg-block flxed-top">
                <ul>
                  {/* <li>
                    <a href="/">Home</a>
                  </li> */}
                  <li>
                    <a href="/dashboard">Dashboard</a>
                  </li>

                  <li>
                    <a href="/users">Users</a>
                  </li>
                  <li>
                    <a href="/add-careers">Careers</a>
                  </li>
                  <li>
                    <a href="/account">Account</a>
                  </li>
                  <li class="drop-down">
                    <a href="#"> {user.user && user.user.user.email}</a>
                    <ul>
                      <li>
                        {/* <a onClick={() => this.props.chin_lang("ch")}> */}
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => this.logout()}
                        >
                          Logout
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        {/* Mobile Navigation */}

        {/* <nav class="mobile-nav d-lg-none"> */}
        <nav class="mobile-nav d-lg-block">
          <ul>
            <li>
              <button
                type="button"
                class="mobile-nav-toggle d-lg-none"
                style={{ color: "#fff" }}
                onClick={() => this.hideModal()}
              >
                {/* icofont-close */}
                <i
                  class={this.state.showModal === true && "icofont-close"}
                  style={{ color: "#fff !important" }}
                ></i>
              </button>
            </li>
            <li>
              <a href="/dashboard">Dashboard</a>
            </li>

            <li>
              <a href="/users">Users</a>
            </li>
            <li>
              <a href="/account">Account</a>
            </li>
            <li class={this.state.dropD3 ? "drop-down active" : "drop-down "}>
              <a onClick={() => this.setState({ dropD3: !this.state.dropD3 })}>
                {user.user && user.user.user.email}
              </a>
              <ul style={{ display: this.state.dropD3 ? "block " : "none" }}>
                <li>
                  {/* <a onClick={() => this.props.chin_lang("ch")}> */}
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => this.logout()}
                  >
                    Logout
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>

        <div
          class="mobile-nav-overly"
          style={{ display: this.state.showModal === false ? "none" : "block" }}
        ></div>
      </div>
    );
  }
}

// export default Navbar;
// export default Todo;
const mapStateToProps = (state) => {
  console.log(state.reducers);
  return {
    getReduxData: state.data,
    // userR: state.user,
    user: state.reducers,
    // userfb: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // eng_lang: (data) => dispatch(english_lang(data)),
    // chin_lang: (data) => dispatch(chinese_lang(data)),
    logout_user: () => dispatch(remove_user()),
    setLanguage: (language) => dispatch(setLocale(language)),
  };
};
// export default withStyles(styles)(Login);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
