import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
  i18nReducer,
} from "react-redux-i18n";
import storage from "redux-persist/lib/storage";

// import reducer from "./reducers/reducers";
import rootReducer from "./reducers/index";
import translations from "../l10n/translations";
const persistConfig = {
  key: "root",
  storage,
};

// const persistedReducer = persistReducer({
//   persistConfig,
//   reducer,
//   i18n: i18nReducer,
// });
const persistedReducer = persistReducer(persistConfig, rootReducer, {
  i18n: i18nReducer,
});

const store = createStore(persistedReducer, applyMiddleware(thunk));
syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));
store.dispatch(setLocale("en"));
const persistor = persistStore(store);
export { store, persistor };
