import React, { Component } from "react";
import "../../App.css";
import Navbar from "../../config/NavAdmin";
import firebase from "../../config/firebase";
export default class Login extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      checkedActive: false,
      toggleEdit: false,
      arrData: [],
      arrDataAdmin: [],
    };
  }
  async componentDidMount() {
    await this.getData();
    await this.getDataAdm();
  }

  getData = () => {
    // const {
    //   user,
    //   match: { params },
    // } = this.props;
    // console.log(this.props);
    firebase
      .database()
      .ref("User_Data")

      .on("value", (snapshot) => {
        var newArr = [];
        snapshot.forEach((data) => {
          var childData = data.val();
          newArr.push(childData);
          // console.log("Success: ", childData);
        });
        this.setState({
          arrData: newArr.reverse(),
        });
      });
  };
  getDataAdm = () => {
    // const {
    //   user,
    //   match: { params },
    // } = this.props;
    // console.log(this.props);
    firebase
      .database()
      .ref("Admin")

      .on("value", (snapshot) => {
        var newArr = [];
        snapshot.forEach((data) => {
          var childData = data.val();
          newArr.push(childData);
          // console.log("Success: ", childData);
        });
        this.setState({
          arrDataAdmin: newArr.reverse(),
        });
      });
  };
  render() {
    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />

          <main id="main">
            {/* <!-- ======= Breadcrumbs ======= --> */}
            <section id="breadcrumbs" class="breadcrumbs">
              <div class="container ">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li> {this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
              <div class="container text-center">
                {/* <h1> {I18n.t("home.servicesubHeading3").toUpperCase()}</h1> */}
                <h1>Dashboard</h1>
              </div>
            </section>

            {/* <!-- End Breadcrumbs --> */}

            {/* <!-- ======= About Section ======= --> */}
            <section id="about" class="about">
              <div class="container">
                <div class="row">
                  <div class="col-xl-6 col-sm-6 col-12 mt-2">
                    <div class="card " style={{ backgroundColor: "#333" }}>
                      <div class="card-content">
                        <div class="card-body">
                          <div class="media d-flex">
                            <div class="align-self-center">
                              <i class="fas fa-users text-success font-large-2 float-left fa-4x"></i>
                            </div>
                            <div class="media-body text-right">
                              <h5 class="mb-2 text-light">
                                {this.state.arrData.length}
                              </h5>
                              <h3 class=" text-light">Users</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-sm-6 col-12 mt-2">
                    <div class="card" style={{ backgroundColor: "#333" }}>
                      <div class="card-content">
                        <div class="card-body">
                          <div class="media d-flex">
                            <div class="align-self-center">
                              <i class="fas fa-user-circle text-primary font-large-2 float-left fa-4x"></i>
                            </div>
                            <div class="media-body text-right">
                              <h5 class="mb-2 text-light">
                                {this.state.arrDataAdmin.length}
                              </h5>
                              <h3 class=" text-light">Account</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>

        <a onClick={() => this.handleOnHome()} class="back-to-top">
          <i class="icofont-simple-up"></i>
        </a>
      </div>
    );
  }
}
