import React, { Component } from "react";

// import firebase from './firebase'
// import logo from "../../assets/userLogo.png";
// import pix1 from "../../assets/pix1.jpg";
import pix2 from "../../assets/img/logo192.png";
import Navbar from "../../config/Navbar";
import Footer from "../../config/Footer";
import firebase from "../../config/firebase";
var I18n = require("react-redux-i18n").I18n;
export default class BankFinance extends Component {
  constructor() {
    super();
    this.state = {
      arrData: [],
    };
  }
  handleOnHome = (event) => {
    //.current is verification that your element has rendered
    // window.scrollTo(0, this.myRefHome.current.offsetTop);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };

  async componentDidMount() {
    await this.getData();
  }

  getData = () => {
    // const {
    //   user,
    //   match: { params },
    // } = this.props;
    console.log(this.props);
    firebase
      .database()
      .ref("User_Data")

      .on("value", (snapshot) => {
        var newArr = [];
        snapshot.forEach((data) => {
          var childData = data.val();
          newArr.push(childData);
          // console.log("Success: ", childData);
        });
        this.setState({
          arrData: newArr.reverse(),
        });
      });
  };
  render() {
    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />
          <Navbar />
          <main id="main">
            {/* <!-- ======= Breadcrumbs ======= --> */}
            <section id="breadcrumbs" class="breadcrumbs">
              <div class="container ">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li> {this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
              <div class="container text-center">
                <h1> {I18n.t("navbar.navHeading3")}</h1>
              </div>
            </section>

            {/* <!-- End Breadcrumbs --> */}

            <section id="doctors" class="doctors">
              <div class="container">
                <div class="section-title">
                  <p>{I18n.t("OurPros.content")}</p>
                </div>

                <div class="row">
                  {this.state.arrData.map((e) => {
                    // console.log("e", e);
                    return (
                      e.checkedActive === true && (
                        <div class="col-lg-6 mb-4">
                          <div class="member d-flex align-items-start">
                            <div class="pic">
                              <img
                                src={e.downloadURL}
                                class="img-fluid"
                                alt=""
                                style={{ height: "173px", width: "180px" }}
                              />
                            </div>
                            <div class="member-info">
                              <h4>{e.name} </h4>
                              <span>{e.designation}</span>
                              <p>{e.description}</p>
                              <div class="social">
                                <a href={e.fbLink} target="_blank">
                                  <i class="fab fa-facebook-f"></i>
                                </a>
                                <a href={e.twitterLink} target="_blank">
                                  <i class="fab fa-twitter"></i>
                                </a>
                                <a href={e.linkedInLink} target="_blank">
                                  <i class="fab fa-linkedin-in"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
            </section>
          </main>
        </div>
        <div>
          <Footer />
        </div>

        <a onClick={() => this.handleOnHome()} class="back-to-top">
          <i class="icofont-simple-up"></i>
        </a>
      </div>
    );
  }
}
