import React, { Component } from "react";

// import firebase from './firebase'
// import logo from "../../assets/userLogo.png";
// import pix1 from "../../assets/pix1.jpg";
import pix2 from "../../assets/img/logo192.png";
import Navbar from "../../config/Navbar";
import Footer from "../../config/Footer";
import firebase from "../../config/firebase";
import renderHTML from "react-render-html";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
var I18n = require("react-redux-i18n").I18n;
export default class BankFinance extends Component {
  constructor() {
    super();
    this.state = {
      arrData: [],
    };
  }
  handleOnHome = (event) => {
    //.current is verification that your element has rendered
    // window.scrollTo(0, this.myRefHome.current.offsetTop);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };

  async componentDidMount() {
    await this.getData();
  }

  getData = () => {
    // const {
    //   user,
    //   match: { params },
    // } = this.props;
    console.log(this.props);
    firebase
      .database()
      .ref("Careers_Data")

      .on("value", (snapshot) => {
        var newArr = [];
        snapshot.forEach((data) => {
          var childData = data.val();
          newArr.push(childData);
          // console.log("Success: ", childData);
        });
        this.setState({
          arrData: newArr.reverse(),
        });
      });
  };
  render() {
    const { title, para, detail, emailLink } = this.state;
    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />

          <main id="main">
            {/* <!-- ======= Breadcrumbs ======= --> */}
            <section id="breadcrumbs" class="breadcrumbs">
              <div class="container ">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li> {this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
              <div class="container text-center">
                <h1> {I18n.t("navbar.navHeading5")}</h1>
              </div>
            </section>

            {/* <!-- End Breadcrumbs --> */}

            <section id="doctors" class="doctors">
              <div class="container">
                <div class="section-title">
                  <p>{I18n.t("careers.content1")}</p>
                </div>
                <div class="row">
                  {this.state.arrData.map((e, i) => {
                    // console.log("e", e);
                    return (
                      e.checkedActive === true && (
                        <div class="col-lg-12 mb-4" key={i}>
                          <div class="member">
                            <h2>{e.jobTitle}</h2>
                            <p>{e.jobPara}</p>
                            {/* {renderHTML(e.data)} */}
                            {/* <p>Email: {e.emailLink}</p> */}
                            <div class="social d-flex justify-content-end">
                              <button
                                class="btn btn-primary"
                                onClick={() =>
                                  this.setState({
                                    toggleEditImage: !this.state
                                      .toggleEditImage,
                                    title: e.jobTitle,
                                    para: e.jobPara,
                                    detail: e.data,
                                    emailLink: e.emailLink,
                                  })
                                }
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
            </section>
          </main>
        </div>
        <div>
          <Modal
            size="xl"
            isOpen={this.state.toggleEditImage}
            toggle={this.state.toggle}
            //   className={className}
            //   backdrop={backdrop}
            backdrop="static"
            //   keyboard={keyboard}
          >
            <div
              class="mt-4 mr-4 ml-4"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h5 toggle={this.state.toggle}>Detail</h5>
            </div>
            <hr />
            <ModalBody>
              <div>
                <h2>{title}</h2>
                <p>{para}</p>
                <p> {detail && renderHTML(detail)}</p>
                <p>Email: {emailLink}</p>
              </div>
            </ModalBody>
            <ModalFooter>
              {" "}
              <Button
                color="secondary"
                onClick={() =>
                  this.setState({
                    toggleEditImage: !this.state.toggleEditImage,
                  })
                }
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        <div>
          <Footer />
        </div>

        <a onClick={() => this.handleOnHome()} class="back-to-top">
          <i class="icofont-simple-up"></i>
        </a>
      </div>
    );
  }
}
