import React, { Component } from "react";

// import firebase from './firebase'
// import logo from "../../assets/userLogo.png";
// import pix1 from "../../assets/pix1.jpg";
// import pix2 from "../../assets/pix2.jpg";
import Navbar from "../../config/Navbar";
import Footer from "../../config/Footer";
var I18n = require("react-redux-i18n").I18n;
export default class BankFinance extends Component {
  handleOnHome = (event) => {
    //.current is verification that your element has rendered
    // window.scrollTo(0, this.myRefHome.current.offsetTop);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };
  render() {
    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />
          <Navbar />
          <main id="main">
            {/* <!-- ======= Breadcrumbs ======= --> */}
            <section id="breadcrumbs" class="breadcrumbs">
              <div class="container ">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li> {this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
              <div class="container text-center">
                <h1>
                  {" "}
                  {I18n.t("home.servicesubHeading2").toUpperCase()}
                </h1>
              </div>
            </section>

            {/* <!-- End Breadcrumbs --> */}

            {/* <!-- ======= About Section ======= --> */}
            <section id="about" class="about">
              <div class="container">
                <div class="row">
                
                  <div class="col-lg-12 pt-4 pt-lg-0 content">
                  

                    <p class="text-justify">
                    {I18n.t("companySecretarial.content1")}
                    
                    </p>

                    <ul>
                      <li><i class="icofont-check-circled"></i>    {I18n.t("companySecretarial.contentList1")}</li>
                      <li><i class="icofont-check-circled"></i>    {I18n.t("companySecretarial.contentList2")}</li>
                      <li><i class="icofont-check-circled"></i>    {I18n.t("companySecretarial.contentList3")}</li>
                      <li><i class="icofont-check-circled"></i>    {I18n.t("companySecretarial.contentList4")}</li>
                      <li><i class="icofont-check-circled"></i>    {I18n.t("companySecretarial.contentList5")}</li>
                      <li><i class="icofont-check-circled"></i>    {I18n.t("companySecretarial.contentList6")}</li>
                      <li><i class="icofont-check-circled"></i>    {I18n.t("companySecretarial.contentList7")}</li>
                      <li><i class="icofont-check-circled"></i>    {I18n.t("companySecretarial.contentList8")}</li>
                      <li><i class="icofont-check-circled"></i>    {I18n.t("companySecretarial.contentList9")}</li>
                     
                    </ul>
                    <p class="text-justify">
                    {I18n.t("companySecretarial.content2")}
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section id="clients" class="clients">
              <div class="container">
                <div class="accordion" id="accordionExample">
                  <div class="card">
                    <div
                      class="card-header1"
                      id="headingThree"
                      style={{ backgroundColor: "#0690ce" }}
                    >
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          style={{ color: "white", textDecoration: "none" }}
                        >
                          {I18n.t("companySecretarial.heading1")}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseThree"
                      class="collapse show"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        <p class="text-justify">
                        {I18n.t("companySecretarial.heading1Content1")}
                        </p>

                        <div class="content">
                          <ul style={{ listStyle: "none" }}>
                            <li style={{ fontSize: "16px" }}>
                              <i
                                class="icofont-check-circled"
                                style={{
                                  fontSize: "16px",
                                  paddingRight: "4px",
                                  color: " #0690ce",
                                }}
                              >
                                {" "}
                              </i>
                              {I18n.t("companySecretarial.heading1List1")}
                            </li>
                            <li style={{ fontSize: "16px" }}>
                              <i
                                class="icofont-check-circled"
                                style={{
                                  fontSize: "16px",
                                  paddingRight: "4px",
                                  color: " #0690ce",
                                }}
                              >
                                {" "}
                              </i>
                              {I18n.t("companySecretarial.heading1List2")}
                            </li>
                            <li style={{ fontSize: "16px" }}>
                              <i
                                class="icofont-check-circled"
                                style={{
                                  fontSize: "16px",
                                  paddingRight: "4px",
                                  color: " #0690ce",
                                }}
                              >
                                {" "}
                              </i>
                              {I18n.t("companySecretarial.heading1List3")}
                            </li>
                          </ul>
                          <p class="text-justify">
                          {I18n.t("companySecretarial.heading1Content2")}
                          </p>
                          <p class="text-justify">
                          {I18n.t("companySecretarial.heading1Content3")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
        <div>
          <Footer />
        </div>

        <a onClick={() => this.handleOnHome()} class="back-to-top">
          <i class="icofont-simple-up"></i>
        </a>
      </div>
    );
  }
}
