import React, { Component } from "react";

// import firebase from './firebase'
// import logo from "../../assets/userLogo.png";
// import pix1 from "../../assets/pix1.jpg";
// import pix2 from "../../assets/pix2.jpg";
import Navbar from "../../config/Navbar";
import Footer from "../../config/Footer";
var I18n = require("react-redux-i18n").I18n;
export default class BankFinance extends Component {
  handleOnHome = (event) => {
    //.current is verification that your element has rendered
    // window.scrollTo(0, this.myRefHome.current.offsetTop);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };
  render() {
    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />
          <Navbar />
          <main id="main">
            {/* <!-- ======= Breadcrumbs ======= --> */}
            <section id="breadcrumbs" class="breadcrumbs">
              <div class="container ">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li> {this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
              <div class="container text-center">
                <h1> {I18n.t("aboutData.content2").toUpperCase()}</h1>
              </div>
            </section>

            {/* <!-- End Breadcrumbs --> */}

            {/* <!-- ======= About Section ======= --> */}
            <section id="contact" class="contact">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="info-box mb-4">
                      <i class="bx bx-map"></i>
                      <h3>{I18n.t("aboutData.content8")}</h3>
                      <p>
                        201, Asad chambers, near Sindh High Court, Saddar,
                        Karachi
                      </p>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-6">
                    <div class="info-box  mb-4" style={{ height: "88%" }}>
                      {/* <i class="bx bx-envelope"></i> */}
                      <i class="far fa-clock"></i>
                      <h3>{I18n.t("aboutData.content9")}</h3>
                      <p>
                        {/* [email&#160;protected] */}
                        9:00 AM - 8:00 PM (
                        {I18n.t("aboutData.contentSchedule1")})<br />
                        10:00 AM - 4:00 PM (
                        {I18n.t("aboutData.contentSchedule2")})
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6">
                    <div class="info-box  mb-4" style={{ height: "88%" }}>
                      <i class="bx bx-envelope"></i>
                      <h3>{I18n.t("aboutData.content6")}</h3>
                      <p>
                        <a
                          //   href="/cdn-cgi/l/email-protection"
                          href="mailto:   info@sayyidandshaykh.com"
                          class="__cf_email__"
                          //   data-cfemail="fe9d91908a9f9d8abe9b869f938e929bd09d9193"
                        >
                          {/* [email&#160;protected] */}
                          info@sayyidandshaykh.com
                        </a>
                      </p>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-6">
                    <div class="info-box  mb-4">
                      <i class="bx bx-phone-call"></i>
                      <h3>{I18n.t("aboutData.content10")}</h3>
                      <p>
                        {I18n.t("aboutData.content5")}: +92 (0) 21 38887114 - 5
                      </p>

                      <p>
                        {I18n.t("aboutData.content7")}: +92 (0) 316 162 0905
                      </p>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12 ">
                    <iframe
                      class="mb-4 mb-lg-0"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3620.149102655199!2d67.0211734143711!3d24.858756651449347!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33fd3e2b3e0a3%3A0xf5ff965d653d0bde!2sAsad%20chamber!5e0!3m2!1sen!2s!4v1617538888686!5m2!1sen!2s"
                      frameborder="0"
                      style={{ border: 0, width: "100%", height: "384px" }}
                      allowfullscreen
                    ></iframe>
                  </div>

                  {/* <div class="col-lg-6">
                    <form
                      action="forms/contact.php"
                      method="post"
                      role="form"
                      class="php-email-form"
                    >
                      <div class="row">
                        <div class="col-md-6 form-group">
                          <input
                            type="text"
                            name="name"
                            class="form-control"
                            id="name"
                            placeholder="Your Name"
                            required
                          />
                        </div>
                        <div class="col-md-6 form-group mt-3 mt-md-0">
                          <input
                            type="email"
                            class="form-control"
                            name="email"
                            id="email"
                            placeholder="Your Email"
                            required
                          />
                        </div>
                      </div>
                      <div class="form-group mt-3">
                        <input
                          type="text"
                          class="form-control"
                          name="subject"
                          id="subject"
                          placeholder="Subject"
                          required
                        />
                      </div>
                      <div class="form-group mt-3">
                        <textarea
                          class="form-control"
                          name="message"
                          rows="5"
                          placeholder="Message"
                          required
                        ></textarea>
                      </div>
                      <div class="my-3">
                        <div class="loading">Loading</div>
                        <div class="error-message"></div>
                        <div class="sent-message">
                          Your message has been sent. Thank you!
                        </div>
                      </div>
                      <div class="text-center">
                        <button type="submit">Send Message</button>
                      </div>
                    </form>
                  </div> */}
                </div>
              </div>
            </section>
          </main>
        </div>
        <div>
          <Footer />
        </div>

        <a onClick={() => this.handleOnHome()} class="back-to-top">
          <i class="icofont-simple-up"></i>
        </a>
      </div>
    );
  }
}
