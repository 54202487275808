import React, { Component } from "react";

// import firebase from './firebase'
// import logo from "../../assets/userLogo.png";
// import pix1 from "../../assets/pix1.jpg";
import pix2 from "../../assets/img/logo192.png";
import Navbar from "../../config/NavAdmin";
import firebase from "../../config/firebase";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
var database = firebase.database();
export default class BankFinance extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      checkedActive: false,
      toggleEdit: false,
      arrData: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }
  addUserModal = () => {
    // let nameImage = image.substr(
    //   image.indexOf("%2F") + 3,
    //   image.indexOf("?") - (image.indexOf("%2F") + 3)
    // );
    // nameImage = nameImage.replace("%20", " ");
    // this.name.value = "";
    // console.log('this.name.value ', this )
    this.setState({
      modal: !this.state.modal,
      name: "",
      designation: "",
      description: "",
      fbLink: "",
      twitterLink: "",
      linkedInLink: "",
      image: "",
      imageEdit: "",
      checkedActive: false,
      toggleEdit: false,
    });
  };

  async componentDidMount() {
    await this.getData();
  }

  getData = () => {
    // const {
    //   user,
    //   match: { params },
    // } = this.props;
    console.log(this.props);
    firebase
      .database()
      .ref("User_Data")

      .on("value", (snapshot) => {
        var newArr = [];
        snapshot.forEach((data) => {
          var childData = data.val();
          newArr.push(childData);
          // console.log("Success: ", childData);
        });
        this.setState({
          arrData: newArr.reverse(),
        });
      });
  };

  handleChangeImage = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];

      this.setState(() => ({ image }));
    }
  };
  handleUpdateImage = (e) => {
    if (e.target.files[0]) {
      const imageUpdate = e.target.files[0];

      this.setState(() => ({ imageUpdate }));
    }
  };

  handleCheck = () => {
    this.setState({
      checkedActive: !this.state.checkedActive,
    });
  };
  handleOnHome = (event) => {
    //.current is verification that your element has rendered
    // window.scrollTo(0, this.myRefHome.current.offsetTop);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };

  addData = () => {
    const {
      name,
      designation,
      description,
      fbLink,
      twitterLink,
      linkedInLink,
      image,
      // customFile,
      checkedActive,
    } = this.state;
    this.setState({
      loaderToggle: true,
    });
    // console.log(checkedActive);
    if (image == null || image.name === undefined) {
      alert("please add image");
      this.setState({
        loaderToggle: false,
      });
    } else {
      var userDataKey = firebase.database().ref().child("User_Data").push().key;
      const path = "Avatar_" + userDataKey + ".jpg";
      const uploadTask = firebase
        .storage()
        .ref(`User_Data/${path}`)
        .put(image)
        .then((snapshot) => {
          return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
        })

        .then((downloadURL) => {
          console.log(
            `Successfully uploaded file and got download link - ${downloadURL}`
          );
          // return downloadURL
          this.setState({ url: downloadURL });
          var obj = {
            name,
            designation,
            description,
            fbLink,
            twitterLink,
            linkedInLink,
            image,
            // customFile,
            checkedActive,
            downloadURL,
            createAt: new Date().toLocaleString(),
            userDataKey,
          };
          if (
            name === "" ||
            name === undefined ||
            designation === "" ||
            designation === undefined ||
            description === "" ||
            description === undefined ||
            fbLink === "" ||
            fbLink === undefined ||
            twitterLink === "" ||
            twitterLink === undefined ||
            linkedInLink === "" ||
            linkedInLink === undefined ||
            checkedActive === "" ||
            checkedActive === undefined
          ) {
            alert("Please Fill All field");
            this.setState({
              loaderToggle: false,
            });
          } else {
            firebase
              .database()
              .ref("User_Data/" + userDataKey)
              .set(obj)
              .then((e) => {
                this.setState({
                  // success: "Successfully",
                  // visible: true,
                  modal: !this.state.modal,
                  loaderToggle: false,
                });
              })
              .catch((error) => {
                console.log("Error: ", error.message);
                // this.setState({
                //   errorMessage: error.message,
                //   visiableError: true,
                // });
              });
            // this.setState({
            //   headingName: "",
            //   body: "",
            //   githubLink: "",
            //   buttonLink: "",
            // });
          }
        })
        .catch((error) => {
          // Use to signal error if something goes wrong.
          console.log(`Failed to upload file and get link - ${error}`);
          // this.setState({
          //   errorMessage: error.message,
          //   visiableError: true,
          // });
        });
    }
  };

  deleteUser = (id, image) => {
    console.log("id", id);
    database
      .ref("User_Data/" + id)
      .remove()
      .then((success) => {
        // this.setState({ open: false });
        let name = image.substr(
          image.indexOf("%2F") + 3,
          image.indexOf("?") - (image.indexOf("%2F") + 3)
        );
        name = name.replace("%20", " ");
        console.log(name);
        let storagePath = firebase.storage().ref();
        storagePath
          .child(`User_Data/${name}`)
          .delete()
          .then(() => {
            // File deleted successfully
            console.log("deleted");
          })
          .catch((error) => {
            // Uh-oh, an error occurred!
          });
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  };
  handleUpdate = (
    name,
    designation,
    description,
    fbLink,
    twitterLink,
    linkedInLink,
    image,
    checkedActive,
    userDataKey,
    downloadURL
  ) => {
    let nameImage = image.substr(
      image.indexOf("%2F") + 3,
      image.indexOf("?") - (image.indexOf("%2F") + 3)
    );
    nameImage = nameImage.replace("%20", " ");
    // console.log("nameImage", nameImage);
    // console.log("image", image);
    this.setState({
      name,
      designation,
      description,
      fbLink,
      twitterLink,
      linkedInLink,
      imageEdit: nameImage,
      checkedActive,
      userDataKey,
      modal: !this.state.modal,
      toggleEdit: true,
      downloadURL,
    });
  };

  editUser = () => {
    const {
      name,
      designation,
      description,
      fbLink,
      twitterLink,
      linkedInLink,
      image,
      imageEdit,
      checkedActive,
      userDataKey,
    } = this.state;
    this.setState({
      loaderToggle: true,
    });
    console.log("imageEdit", imageEdit);

    var imageDat = image == null || image.name === undefined;
    console.log("image", image);
    console.log("imageDat", imageDat);
    var imageData = imageDat === true ? imageEdit : image.name;
    if (imageEdit) {
      // if (image == null || image.name === undefined) {
      // alert("please add image");
      // this.setState({
      //   loaderToggle: false,
      // });
      var obj = {
        name,
        designation,
        description,
        fbLink,
        twitterLink,
        linkedInLink,
        // image,
        checkedActive,
        // downloadURL,
        updateDate: new Date().toLocaleString(),
        userDataKey,
      };
      if (
        name === "" ||
        name === undefined ||
        designation === "" ||
        designation === undefined ||
        description === "" ||
        description === undefined ||
        fbLink === "" ||
        fbLink === undefined ||
        twitterLink === "" ||
        twitterLink === undefined ||
        linkedInLink === "" ||
        linkedInLink === undefined ||
        checkedActive === "" ||
        checkedActive === undefined
      ) {
        alert("Please Fill All field");
        this.setState({
          loaderToggle: false,
        });
      } else {
        firebase
          .database()
          .ref("User_Data/" + userDataKey)
          .update(obj)
          .then((e) => {
            this.setState({
              // success: "Successfully",
              // visible: true,
              modal: !this.state.modal,
              loaderToggle: false,
              // imageEdit: "",
              // image: "",
            });
            // window.location.reload();
          })
          .catch((error) => {
            console.log("Error: ", error.message);
            // this.setState({
            //   errorMessage: error.message,
            //   visiableError: true,
            // });
          });
        // this.setState({
        //   headingName: "",
        //   body: "",
        //   githubLink: "",
        //   buttonLink: "",
        // });
      }
      // } else {
    }
  };

  userImageUpdat = () => {
    const {
      localImage,
      image,
      userDataKey,
      imageUpdate,
      localImageKey,
    } = this.state;
// console.log(localImage)
    this.setState({
      loaderToggle: true,
    });
    const path = "Avatar_" + localImageKey + ".jpg";
    const uploadTask = firebase
      .storage()
      // .ref(`User_Data/${image.name}`)
      .ref(`User_Data/${path}`)
      .put(imageUpdate)
      .then((snapshot) => {
        return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
      })
      .then((downloadURL) => {
        console.log(
          `Successfully uploaded file and got download link - ${downloadURL}`
        );
        // return downloadURL
        this.setState({ url: downloadURL });
        var obj = {
          downloadURL,
        };

        firebase
          .database()
          .ref("User_Data/" + localImageKey)
          .update(obj)
          .then((e) => {
            this.setState({
              toggleEditImage: !this.state.toggleEditImage,
              loaderToggle: false,
            });
            // window.location.reload();
          })
          .catch((error) => {
            console.log("Error: ", error.message);
          });
      })
      .catch((error) => {
        console.log(`Failed to upload file and get link - ${error}`);
      });
  };

  render() {
    const {
      loaderToggle,
      arrData,
      toggleEdit,
      checkedActive,
      imageEdit,
      downloadURL,
      localImage,
    } = this.state;
    console.log(localImage);

    var imageName = this.state.image && this.state.image.name;
    var imageData = imageName ? imageName : imageEdit;

    return (
      <div>
        <div ref={this.myRefHome}>
          <Navbar />

          <main id="main">
            {/* <!-- ======= Breadcrumbs ======= --> */}
            <section id="breadcrumbs" class="breadcrumbs">
              <div class="container ">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li> {this.props.match.path.replace(/[#_/-]/g, " ")}</li>
                </ol>
              </div>
              <div class="container text-center">
                {/* <h1> {I18n.t("home.servicesubHeading3").toUpperCase()}</h1> */}
                <h1>Users</h1>
              </div>
            </section>

            {/* <!-- End Breadcrumbs --> */}

            <section id="doctors" class="doctors">
              <div class="container">
                <div class="d-flex justify-content-end">
                  <button
                    class="btn btn-primary mb-3 "
                    onClick={() => this.addUserModal()}
                  >
                    Add User
                  </button>
                </div>

                <div class="row">
                  {arrData.map((e, i) => {
                    // console.log("e", e);
                    return (
                      <div class="col-lg-6 mb-4" key={i}>
                        <div class="member d-flex align-items-start">
                          <div class="pic">
                            <img
                              onClick={() =>
                                this.setState({
                                  toggleEditImage: !this.state.toggleEditImage,
                                  localImage: e.downloadURL,
                                  localImageKey: e.userDataKey,
                                })
                              }
                              src={e.downloadURL}
                              class="img-fluid"
                              alt=""
                              style={{ height: "173px", width: "180px" }}
                            />
                          </div>

                          {/* <div
                            class="social"
                            style={{
                              // display: "flex",
                              // justifyContent: "flex-end",
                              left: '11.5rem',
                              bottom: '3rem',
                              position: "absolute",
                              color: "green",
                            }}
                          >
                            <a  class="bg-success" href={e.fbLink} target="_blank">
                              <i class="fas fa-pen"></i>
                            </a>
                          </div> */}
                          <div class="member-info">
                            {e.checkedActive === true ? (
                              <p
                                style={{
                                  // display: "flex",
                                  // justifyContent: "flex-end",
                                  right: 30,
                                  top: 0,
                                  position: "absolute",
                                  color: "green",
                                }}
                              >
                                Active
                              </p>
                            ) : (
                              <p
                                style={{
                                  // display: "flex",
                                  // justifyContent: "flex-end",
                                  right: 30,
                                  top: 0,
                                  position: "absolute",
                                  // fontWeight: 600 ,
                                  color: "red",
                                }}
                              >
                                Not Active
                              </p>
                            )}

                            <h4>{e.name} </h4>
                            <span>{e.designation}</span>
                            <p>{e.description}</p>
                            <div class="social">
                              <a href={e.fbLink} target="_blank">
                                <i class="fab fa-facebook-f"></i>
                              </a>
                              <a href={e.twitterLink} target="_blank">
                                <i class="fab fa-twitter"></i>
                              </a>
                              <a href={e.linkedInLink} target="_blank">
                                <i class="fab fa-linkedin-in"></i>
                              </a>{" "}
                              <a
                                onClick={() =>
                                  this.handleUpdate(
                                    e.name,
                                    e.designation,
                                    e.description,
                                    e.fbLink,
                                    e.twitterLink,
                                    e.linkedInLink,
                                    e.downloadURL,
                                    e.checkedActive,
                                    e.userDataKey
                                  )
                                }
                                class="bg-success"
                              >
                                <i class="fas fa-pen"></i>
                              </a>
                              <a
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you sure want to Delete this User?"
                                    )
                                  ) {
                                    this.deleteUser(
                                      e.userDataKey,
                                      e.downloadURL
                                    );
                                  }
                                }}
                                class="bg-danger"
                              >
                                <i class="fas fa-trash"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          </main>
          <div>
            <Modal
              size="xl"
              isOpen={this.state.modal}
              toggle={this.state.toggle}
              //   className={className}
              //   backdrop={backdrop}
              backdrop="static"
              //   keyboard={keyboard}
            >
              <div
                class="mt-4 mr-4 ml-4"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h5 toggle={this.state.toggle}>
                  {toggleEdit === false ? "Add User" : "Edit User"}
                </h5>
                {loaderToggle && <Spinner color="primary" />}
              </div>
              <hr />
              <ModalBody>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label class="small mb-1" for="inputEmailAddress">
                      Name
                    </label>
                    <input
                      class="form-control py-4"
                      id="inputEmailAddress"
                      placeholder="Enter Name"
                      name="name"
                      type="text"
                      value={this.state.name}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="small mb-1" for="inputDesignationAddress">
                      Position / Designation
                    </label>
                    <input
                      class="form-control py-4"
                      id="inputDesignationAddress"
                      placeholder="Enter Position / Designation"
                      name="designation"
                      type="text"
                      value={this.state.designation}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div class="form-group col-md-12">
                    <label for="exampleFormControlTextarea1">Description</label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Enter Description"
                      name="description"
                      type="text"
                      value={this.state.description}
                      onChange={this.handleChange}
                    ></textarea>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-4">
                    <label class="small mb-1">Facebook Link</label>
                    <input
                      class="form-control py-4"
                      // id="inputEmailAddress"
                      placeholder="Enter Facebook Link"
                      name="fbLink"
                      type="text"
                      value={this.state.fbLink}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label class="small mb-1">Twitter Link</label>
                    <input
                      class="form-control py-4"
                      // id="inputEmailAddress"
                      placeholder="Enter Twitter Link"
                      name="twitterLink"
                      type="text"
                      value={this.state.twitterLink}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label class="small mb-1">LinkedIn Link</label>
                    <input
                      class="form-control py-4"
                      // id="inputEmailAddress"
                      placeholder="Enter LinkedIn Link"
                      name="linkedInLink"
                      type="text"
                      value={this.state.linkedInLink}
                      onChange={this.handleChange}
                    />
                  </div>
                  {toggleEdit === false ? (
                    <div
                      class="custom-file mr-3 ml-3"
                      style={{ justifyContent: "center", display: "flex" }}
                    >
                      {/* <label for="exampleCustomFileBrowser">Choose File</label> */}
                      <input
                        type="file"
                        class="custom-file-input"
                        id="customFile"
                        name={imageData}
                        // value={this.state.image && this.state.image.name}
                        onChange={this.handleChangeImage}
                        // inputRef={(el) => (this.value = el)}
                      />
                      <label class="custom-file-label" for="customFile">
                        {imageData}
                      </label>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </ModalBody>
              <ModalFooter
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div
                  class="form-check"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <input
                    checked={checkedActive}
                    type="checkbox"
                    class="form-check-input"
                    id="exampleCheck1"
                    name="checkedActive"
                    value={checkedActive}
                    onChange={this.handleCheck}
                  />
                  <label class="form-check-label" for="exampleCheck1">
                    Active User
                  </label>
                </div>
                <div>
                  {toggleEdit === false ? (
                    <Button
                      className="mr-2"
                      color="primary"
                      onClick={
                        () => this.addData()
                        // this.setState({
                        //   modal: !this.state.modal,
                        // })
                      }
                    >
                      Add
                    </Button>
                  ) : (
                    <Button
                      className="mr-2"
                      color="primary"
                      onClick={
                        () => this.editUser()
                        // this.setState({
                        //   modal: !this.state.modal,
                        // })
                      }
                    >
                      Edit
                    </Button>
                  )}
                  <Button
                    color="secondary"
                    onClick={() =>
                      this.setState({
                        modal: !this.state.modal,
                        imageEdit: "",
                        image: "",
                      })
                    }
                  >
                    Cancel
                  </Button>
                </div>
              </ModalFooter>
            </Modal>
          </div>
          <div>
            <Modal
              size="md"
              isOpen={this.state.toggleEditImage}
              toggle={this.state.toggle}
              //   className={className}
              //   backdrop={backdrop}
              backdrop="static"
              //   keyboard={keyboard}
            >
              <div
                class="mt-4 mr-4 ml-4"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h5 toggle={this.state.toggle}>Edit User Image</h5>
                {loaderToggle && <Spinner color="primary" />}
              </div>
              <hr />
              <ModalBody>
                <div
                  class="pic"
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <img
                    onClick={() =>
                      this.setState({
                        toggleEditImage: !this.state.toggleEditImage,
                      })
                    }
                    src={this.state.localImage}
                    class="img-fluid"
                    alt=""
                    style={{ height: "400px", width: "450px" }}
                  />
                </div>
                <div
                  class="custom-file  mt-4"
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  {/* <label for="exampleCustomFileBrowser">Choose File</label> */}
                  <input
                    type="file"
                    class="custom-file-input"
                    id="customFile"
                    name={imageData}
                    // value={this.state.image && this.state.image.name}
                    onChange={this.handleUpdateImage}
                    // inputRef={(el) => (this.value = el)}
                  />
                  <label class="custom-file-label" for="customFile">
                    {imageData}
                  </label>
                </div>
              </ModalBody>
              <ModalFooter
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>
                  <Button
                    className="mr-2"
                    color="primary"
                    onClick={
                      () => this.userImageUpdat()
                      // this.setState({
                      //   modal: !this.state.modal,
                      // })
                    }
                  >
                    Edit
                  </Button>

                  <Button
                    color="secondary"
                    onClick={() =>
                      this.setState({
                        toggleEditImage: !this.state.toggleEditImage,
                      })
                    }
                  >
                    Cancel
                  </Button>
                </div>
              </ModalFooter>
            </Modal>
          </div>
        </div>

        <a onClick={() => this.handleOnHome()} class="back-to-top">
          <i class="icofont-simple-up"></i>
        </a>
      </div>
    );
  }
}
